import {
    memo,
    Fragment,
    useState,
    useEffect,
    useCallback,
} from 'react';
import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import {
    getEnumValue,
    PermissionsEnum,
    PresencesAbsenceStateEnum,
} from '../../utils/enum';

import Card from '../../components/bootstrap/card';
import { useSortableData } from '../../hooks/useSortableData';
import { useSelection } from '../../hooks/useSelection';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ComboButtonGroup, {
    ComboButtonId,
} from '../../components/ComboButtonGroup';
import { Absence } from '../../interfaces';
import { usePermissions } from '../../hooks/usePermissions';
import SearchInput from '../../components/SearchInput';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericDropdownFilter from '../../components/filter/GenericDropdownFilter';
import { presencesAbsencesStateColorMap, presencesAbsencesStateIconMap } from '../../components/filter/iconAndColorMappings';

interface AbsencesResponse {
    page: number;
    itemsPerPage: number;
    amountPages: number;
    amountAllItems: number;
    list: Absence[];
    searchFilters: string[];
}

const comboButtons = [
    { id: 'all', label: 'Alle' },
];

const Absences = memo(() => {
    const navigate = useNavigate();
    const { companyId = 'oc' } = useParams();
    const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
    const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
    const [availableFilter, setAvailableFilter] = useState<string[]>([]);
    const [absences, setAbsences] = useState<Absence[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(20);
    const [limit, setLimit] = useState<number>(25);
    const [totalEntries, setTotalEntries] = useState<number>(200);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
    const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
    const { items: sortedAbsences, requestSort } = useSortableData(absences);
    const [selectedStateFilter, setSelectedStateFilter] = useState<number | null>(null);

    const {
        selectedItems: selectedAbsences,
        isAllSelected,
        selectedCount,
        handleSelectAll,
        handleDeSelectAll,
        handleSelectRow,
    } = useSelection(absences);

    const handleSearch = (data: { query: string; filter?: string }) => {
        const { query, filter } = data;

        if (query) {
            setSearchQuery(query);
            setSelectedSearchFilter(filter || '');
            setCurrentPage(1);
        } else if (searchQuery) {
            resetSearch();
        }
    };

    const fetchAbsences = useCallback(async () => {
        setAbsences([]);
        setIsLoading(true);
        let queryParams = `?page=${currentPage}`;

        if (limit.toString()) {
            queryParams += `&limit=${limit}`;
        }
        if (selectedStateFilter?.toString()) {
            queryParams += `&state=${selectedStateFilter}`;
        }
        if (searchQuery) {
            queryParams += `&search=${encodeURIComponent(searchQuery)}`;
            if (selectedSearchFilter !== 'all' && selectedSearchFilter) {
                queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
            }
        }

        try {
            const response = await ApiClient.get(`/absences${queryParams}`);
            const certificatesResponse = response.data as AbsencesResponse;
            setTotalPages(certificatesResponse.amountPages);
            setAbsences(certificatesResponse.list);
            setCurrentPage(certificatesResponse.page);
            setLimit(certificatesResponse.itemsPerPage);
            setTotalEntries(certificatesResponse.amountAllItems);
            setAvailableFilter(certificatesResponse.searchFilters)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }, [currentPage, selectedCombo, searchQuery, limit, selectedStateFilter]);


    useEffect(() => {
        if (permissionsLoaded) {
            const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewAbsences, 'read');

            if (hasPermission) {
                fetchAbsences();
            } else {
                navigate('/errors/error404');
            }
        }
    }, [
        currentPage,
        searchQuery,
        selectedCombo,
        fetchAbsences,
        permissionsLoaded,
        limit,
        selectedStateFilter
    ]);


    const handleAbsenceUpdateSubmit = () => {
        fetchAbsences();
    };

    const handleComboSelect = (newCombo: ComboButtonId) => {
        setSelectedCombo(newCombo);
        setCurrentPage(1);
    };

    const resetSearch = () => {
        setSearchQuery('');
        setResetSearchInput(true);
        setCurrentPage(1);
    };

    const handleSelectedStateFilterChange = (status: number | null) => {
        setCurrentPage(1)
        setSelectedStateFilter(status);
    };

    useEffect(() => {
        if (resetSearchInput) {
            setResetSearchInput(false);
        }
    }, [resetSearchInput]);

    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
                <h3>Abwesenheiten</h3>
            </div>
            <Card className="card-block card-stretch card-height">
                <Card.Body>
                    <Row className="d-flex justify-content-between mb-4">
                        <Col md={6}>
                            {searchQuery ?
                                <div className="d-flex align-items-baseline">
                                    <h4 className="m-0">Suchergebnisse</h4>
                                    <span className="ms-3 d-flex align-items-baseline">
                                        <Button
                                            className="m-0 p-0 fs-6"
                                            variant="link"
                                            onClick={resetSearch}
                                        >
                                            Suche beenden
                                        </Button>
                                    </span>
                                </div>

                                : <ComboButtonGroup
                                    buttons={comboButtons}
                                    selectedCombo={selectedCombo}
                                    setSelectedCombo={handleComboSelect}
                                    borderRadius="normal"
                                ></ComboButtonGroup>}
                        </Col>
                        <Col md={3}>
                            <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <GenericDropdownFilter
                                selectedFilter={selectedStateFilter}
                                handleFilterChange={handleSelectedStateFilterChange}
                                filterEnum={PresencesAbsenceStateEnum}
                                colorMap={presencesAbsencesStateColorMap}
                                iconMap={presencesAbsencesStateIconMap}
                                titlePlaceholder="State"
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {selectedCount > 0 && (
                <GeneralSelectionActions
                    selectedItems={selectedAbsences}
                    selectedCount={selectedCount}
                    handleDeSelectAll={handleDeSelectAll}
                    onSubmitSuccess={handleAbsenceUpdateSubmit}
                    amountAllItems={totalEntries}
                    entityType='absences'
                    allowDelete={false}
                ></GeneralSelectionActions>
            )}
            <div style={{ overflowX: 'auto' }}>
                <Table responsive="md" size="sm">
                    <thead>
                        <tr>
                            <th
                                className="d-flex align-items-center"
                                style={{ cursor: 'pointer' }}
                                scope="col"
                                onClick={() => requestSort('title')}
                            >
                                <Form.Check
                                    disabled={!userHasPermissionByRight(PermissionsEnum.ViewAbsences, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewAbsences, 'delete')}
                                    className="me-3"
                                    type="checkbox"
                                    checked={isAllSelected}
                                    onChange={handleSelectAll}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                />
                                Titel
                            </th>
                            <th
                                style={{ width: '80px' }}
                                scope="col"
                                onClick={() => requestSort('year')}
                            >
                                Jahr
                            </th>
                            <th
                                style={{ width: '80px' }}
                                scope="col"
                                onClick={() => requestSort('calendarWeek')}
                            >
                                KW
                            </th>
                            <th
                                style={{ width: '80px' }}
                                scope="col"
                                onClick={() => requestSort('state')}
                            >
                                State
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {isLoading
                            ? Array.from({ length: 8 }).map((_, index) => (
                                <SkeletonRow key={index} columnCount={4} />
                            ))
                            : sortedAbsences.map((absence) => (
                                <tr key={absence.id} className="bg-white">
                                    <td className="d-flex align-items-center">
                                        <Form.Check
                                            disabled={!userHasPermissionByRight(PermissionsEnum.ViewAbsences, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewAbsences, 'delete')}
                                            className="me-3"
                                            type="checkbox"
                                            checked={
                                                selectedAbsences[absence.id] ?? false
                                            }
                                            onChange={() => { }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleSelectRow(absence.id, e)
                                            }}
                                        />
                                        <Link
                                            to={`/${companyId}/absences/${absence.id}`}
                                            className="btn btn-link ps-0 text-start"
                                            style={{ overflowWrap: 'break-word', flex: 1 }}
                                        >
                                            {absence.title}
                                        </Link>
                                    </td>
                                    <td>
                                        {absence.year}
                                    </td>
                                    <td>
                                        {absence.calendarWeek}
                                    </td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={presencesAbsencesStateIconMap[absence.state]}
                                            className={`me-2 ${presencesAbsencesStateColorMap[absence.state]}`}
                                        />
                                        {getEnumValue(PresencesAbsenceStateEnum, absence.state.toString())}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
                {!isLoading && sortedAbsences.length === 0 && (
                    <div
                        className="d-flex justify-content-center align-items-center border rounded my-3"
                        style={{ height: '50px' }}
                    >
                        <p className="p-0 m-0">Keine Abwesenheiten gefunden</p>
                    </div>
                )}
            </div>

            {sortedAbsences.length > 0 && (
                <Row>
                    <Col>
                        <PaginationInfo
                            currentPage={currentPage}
                            limit={limit}
                            totalEntries={totalEntries}
                            onLimitChange={(size) => {
                                setLimit(size);
                                setCurrentPage(1);
                            }}
                        />
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <DynamicPagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Col>
                </Row>
            )}
        </Fragment>
    );
});

export default Absences;
