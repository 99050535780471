import React from 'react';
import { FieldConfig, Participant } from '../../../interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faEnvelope, faPhone, faMapMarkerAlt, faMobile, faIdCard } from '@fortawesome/pro-regular-svg-icons';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { faDiscord, faHubspot } from '@fortawesome/free-brands-svg-icons';
import { getFieldLabel } from '../../../utils/utils';

interface ParticipantDetailsProps {
  isLoading?: boolean;
  participant?: Participant | null;
  fieldConfigs: FieldConfig[];
}

const ParticipantDetails: React.FC<ParticipantDetailsProps> = ({
  isLoading = false,
  participant,
  fieldConfigs,
}) => {
  const address = participant?.address && participant?.zipCode && participant?.city
    ? `${participant.address}, ${participant.zipCode} ${participant.city}`
    : '-';

  const fields = [
    {
      icon: faCircleUser,
      label: getFieldLabel(fieldConfigs, 'participants.title'),
      value: participant?.title,
    },
    {
      icon: faEnvelope,
      label: getFieldLabel(fieldConfigs, 'participants.email'),
      value: participant?.email,
    },
    ...(participant?.emailScnd
      ? [
          {
            icon: faEnvelope,
            label: getFieldLabel(fieldConfigs, 'participants.emailScnd'),
            value: participant?.emailScnd,
          },
        ]
      : []),
    {
      icon: faPhone,
      label: getFieldLabel(fieldConfigs, 'participants.phoneNumber'),
      value: participant?.phoneNumber,
    },
    {
      icon: faMobile,
      label: getFieldLabel(fieldConfigs, 'participants.mobileNumber'),
      value: participant?.mobileNumber,
    },
    {
      icon: faMapMarkerAlt,
      label: getFieldLabel(fieldConfigs, 'participants.address'),
      value: address,
    },
    {
      icon: faHubspot,
      label: getFieldLabel(fieldConfigs, 'participants.hubspotId'),
      value: participant?.hubspotId,
    },
    {
      icon: faDiscord,
      label: getFieldLabel(fieldConfigs, 'participants.discordId'),
      value: participant?.discordId,
    },
    {
      icon: faIdCard,
      label: "Teilnehmer-ID",
      value: participant?.id,
    },
  ];

  return (
    <div>
      {fields.map((field, index) => (
        <Row key={index} className="mb-2 align-items-start">
          <Col xs={5} className="text-wrap">
            <FontAwesomeIcon
              width={15}
              icon={field.icon}
              className="me-2 text-muted"
            />
            {field.label}
          </Col>
          <Col xs={7} className="text-wrap text-black">
            {isLoading ? <Skeleton width={150} /> : field.value || '-'}
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default ParticipantDetails;
