import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { EventInput } from '@fullcalendar/core';
import tippy from 'tippy.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { Agenda, UnitEntry, Participant, LearningSummary } from '../../../interfaces';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import ReactDOM from 'react-dom/client';
import SetActualTimeModal from '../modal/SetActualTimeModal';

type AgendaCalendarProps = {
  participant: Participant;
  calendarRef: React.RefObject<FullCalendar>;
  onSubmitSuccess: (message?: string, isError?: boolean) => void;
};

type DayInfo = {
  color: string;
  timeDiffFormat: string;
};

/**
 * AgendaCalendar Component
 *
 * This component displays a monthly calendar using FullCalendar to show agenda events
 * and the participant's learning summary. It fetches all required data efficiently
 * and dynamically updates the UI.
 *
 * Features:
 * - Fetches agenda and learning summary data **concurrently** using `Promise.all` to improve performance.
 * - Displays events related to a participant's schedule.
 * - Highlights days based on the `actualTimeDiff` value:
 *   - Negative values (time deficit) → Red (#F2D6D3)
 *   - Positive values (time surplus) → Green (#D5EBDF)
 * - Displays UE (Unterrichtseinheiten) values for each event.
 * - Uses tooltips to show event details on hover.
 * - Dynamically generates date labels for the "Lernzeitkonto" section based on the current month/year.
 * - Efficiently manages the `isLoading` state to ensure a smooth user experience.
 * - Adds a clock icon that appears only when hovering over a specific day.
 *
 * Props:
 * - `participant` (Participant): The participant whose agenda and learning summary should be displayed.
 * - `calendarRef` (React.RefObject<FullCalendar>): A reference to the FullCalendar instance.
 */
const AgendaCalendar: React.FC<AgendaCalendarProps> = ({ participant, calendarRef, onSubmitSuccess }) => {
  const [agenda, setAgenda] = useState<EventInput[]>([]);
  const [dayInfos, setDayInfos] = useState<Record<string, DayInfo>>({});
  const [currentDate, setCurrentDate] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [learningSummary, setLearningSummary] = useState<LearningSummary | null>(null);
  const [actualTimesModalData, setActualTimesModalData] = useState<{ date: string } | null>(
    null
  );

  const fetchData = async (dealId: number) => {
    setIsLoading(true);
    try {
      const [agendaResponse, learningSummaryResponse] = await Promise.all([
        ApiClient.get(`/deals/${dealId}/agenda`),
        ApiClient.get(`/deals/${dealId}/learningSummary`),
      ]);

      const agendaData: Agenda = agendaResponse.data;
      const transformedEvents: EventInput[] = [];
      const dayInfosMap: Record<string, DayInfo> = {};

      Object.entries(agendaData).forEach(([dateKey, agendaData]) => {
        agendaData.units.forEach((unit: UnitEntry) => {
          transformedEvents.push({
            title: unit.unit.title,
            start: dateKey,
            extendedProps: {
              ues: `${unit.usedUes} UE`,
            },
          });
        });

        const eventDate = new Date(dateKey);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (eventDate < today) {
          dayInfosMap[dateKey] = {
            timeDiffFormat: agendaData.actualTimeDiffFormat,
            color: agendaData.actualTimeDiff < 0 ? '#F2D6D3' : '#D5EBDF',
          };
        }
      });

      setAgenda(transformedEvents);
      setDayInfos(dayInfosMap);
      setLearningSummary(learningSummaryResponse.data);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCurrentDate = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const date = calendarApi.getDate();
      const month = date.toLocaleString('DE', { month: 'long' });
      const year = date.getFullYear();
      setCurrentDate(`${month} ${year}`);
    }
  };

  const handleDayCellRendering = (info: { date: Date; el: HTMLElement }) => {
    const dateObj = new Date(info.date.getTime() - info.date.getTimezoneOffset() * 60000);
    const dateStr = dateObj.toISOString().split('T')[0];

    if (!dayInfos[dateStr]) {
      return;
    }

    const dayData = dayInfos[dateStr];
    const dayNumberEl = info.el.querySelector('.fc-daygrid-day-number') as HTMLElement;
    if (!dayNumberEl) return;

    dayNumberEl.style.setProperty('background-color', dayData.color, 'important');
    dayNumberEl.style.setProperty('width', '25px', 'important');
    dayNumberEl.style.setProperty('text-align', 'center', 'important');
    const wrapperEl = document.createElement('div');
    wrapperEl.style.display = 'flex';
    wrapperEl.style.alignItems = 'center';
    wrapperEl.style.justifyContent = 'center';
    wrapperEl.style.gap = '5px';

    const iconContainer = document.createElement('span');
    iconContainer.classList.add('clock-icon-wrapper');

    const timeDiffContainer = document.createElement('span');
    timeDiffContainer.textContent = dayData.timeDiffFormat;
    timeDiffContainer.style.fontSize = '0.9em';

    dayNumberEl.parentNode?.replaceChild(wrapperEl, dayNumberEl);

    wrapperEl.appendChild(iconContainer);
    wrapperEl.appendChild(timeDiffContainer);
    wrapperEl.appendChild(dayNumberEl);

    ReactDOM.createRoot(iconContainer).render(
      <FontAwesomeIcon className="clock-icon cursor-pointer" icon={faClock} onClick={() => setActualTimesModalData({ date: dateStr })} />
    );
  };

  useEffect(() => {
    updateCurrentDate();
  }, []);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const handleDatesSet = () => updateCurrentDate();
      calendarApi.on('datesSet', handleDatesSet);
      return () => {
        calendarApi.off('datesSet', handleDatesSet);
      };
    }
  }, [calendarRef]);

  useEffect(() => {
    if (participant?.lastDeal?.id) {
      fetchData(participant.lastDeal.id);
    }
  }, [participant]);

  return (
    <div className="monthly-calendar">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '500px' }}>
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <p className="fs-5">{currentDate}</p>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={agenda}
            locale="de"
            headerToolbar={false}
            firstDay={1}
            fixedWeekCount={false}
            height="auto"
            contentHeight="auto"
            eventContent={(arg) => {
              const { title } = arg.event;
              const { ues } = arg.event.extendedProps as { ues: string };

              return (
                <div>
                  <div className="fc-event-title">{title}</div>
                  <div className="text-black fw-bold">{ues}</div>
                </div>
              );
            }}
            dayCellDidMount={handleDayCellRendering}
            eventDidMount={(info) => {
              tippy(info.el, {
                content: `${info.event.title} - ${info.event.extendedProps.ues}`,
                placement: 'top',
                theme: 'light-border',
              });
            }}
            datesSet={updateCurrentDate}
          />
          {learningSummary && (
            <Card className="border rounded my-3 shadow-none card-block card-stretch card-height">
              <Card.Body>
                <h5 className="mb-3">Lernzeitkonto</h5>
                <Row>
                  <Col sm={4} className="border-end mb-4 mb-sm-0">
                    <p className="text-muted m-0 mb-2">
                      {`Vor ${new Date().toLocaleString('de-DE', { month: 'long', year: 'numeric' })}`}
                    </p>
                    <p className="fs-4 m-0 text-light-black">{learningSummary.actualTimeDiffBeforeMonthFormat || '-'}</p>
                  </Col>
                  <Col sm={4} className="border-end mb-4 mb-sm-0">
                    <p className="text-muted m-0 mb-2">
                      {`${new Date().toLocaleString('de-DE', { month: 'long', year: 'numeric' })} (bis gestern)`}
                    </p>
                    <p className="fs-4 m-0 text-light-black">{learningSummary.actualTimeDiffMonthFormat || '-'}</p>
                  </Col>
                  <Col sm={4}>
                    <p className="text-muted m-0 mb-2">Gesamt (bis gestern)</p>
                    <p className="fs-4 m-0 text-light-black">{learningSummary.actualTimeDiffTotalFormat || '-'}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
          {actualTimesModalData && (
            <SetActualTimeModal
              onClose={() => setActualTimesModalData(null)}
              onSubmitSuccess={onSubmitSuccess}
              date={actualTimesModalData.date}
              deal={participant.lastDeal}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AgendaCalendar;
