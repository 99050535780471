import React, { useState } from 'react';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { FieldConfig } from '../../interfaces';
import { usePermissions } from '../../hooks/usePermissions';
import { PermissionsEnum } from '../../utils/enum';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortalWrapper from '../PortalWrapper';
import BulkEditModal from '../BulkEditModal';

interface SelectionActionsProps {
  selectedCount: number;
  selectedItems: any;
  handleDeSelectAll: () => void;
  onSubmitSuccess: (message?: string, isError?: boolean) => void;
  publicationStatus?: number | null
  amountAllItems: number,
  fieldConfigs: FieldConfig[]
  entityType: string
  queryParams: string
}

const SelectionActions: React.FC<SelectionActionsProps> = ({
  selectedCount,
  selectedItems,
  handleDeSelectAll,
  onSubmitSuccess,
  publicationStatus,
  amountAllItems,
  fieldConfigs,
  entityType,
  queryParams
}) => {
  const { userHasPermissionByRight } = usePermissions();
  const [isAllSelected, setIsAllSelected] = useState(false)

  return (
    <Row
      className="d-flex justify-content-between p-3 mx-1 mb-2 bg-light-blue"
    >
      <Col className="d-flex align-items-center" md={4}>
        <span>{isAllSelected ? amountAllItems : selectedCount} Elemente wurden ausgewählt</span>
        <Button variant="link" onClick={handleDeSelectAll}>
          Auswahl aufheben
        </Button>
        {
          !isAllSelected && <Button hidden variant="link" className='ps-0' onClick={() => setIsAllSelected(true)}>
            Alle Auswählen ({amountAllItems})
          </Button>
        }
      </Col>
      <Col className="d-flex justify-content-md-end" md={8}>
        <Dropdown className="me-2">
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-basic"
            className="btn-soft-primary dropdown-no-arrow round-button"
          >
            <FontAwesomeIcon className='text-black' size='lg' icon={faEllipsisVertical} />
          </Dropdown.Toggle>
          <PortalWrapper>
            <Dropdown.Menu>
              <BulkEditModal
                modalTitle={`${selectedCount} ${entityType === 'participants' && 'Teilnemer'} bearbeiten`}
                buttonName={`Ausgewählte ${entityType === 'participants' && 'Teilnemer'} bearbeiten`}
                entityIds={isAllSelected ? [] : Object.keys(selectedItems)
                  .filter(key => selectedItems[key] === true)
                  .map(Number)}
                onSubmitSuccess={onSubmitSuccess}
                isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write')}
                entityType={entityType}
                isDropdownItem
                fieldConfigs={fieldConfigs}
                queryParams={queryParams}
              ></BulkEditModal>
            </Dropdown.Menu>
          </PortalWrapper>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default SelectionActions;
