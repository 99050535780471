import { RoleStatus } from "../enum";
import { Permissions } from "../../interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faGear,
  faUser,
  faTags,
} from '@fortawesome/free-solid-svg-icons';
import { faUsers, faBoxesStacked, faHandshake, faImage, faLock, faShieldKeyhole, faUnlock, faUserGraduate, faWebhook, faWavePulse, faClipboardUser, faCalendarXmark, } from "@fortawesome/pro-solid-svg-icons";
import { faFaceSmile } from "@fortawesome/pro-light-svg-icons";

export function getRoleStatusIcon(
  status: RoleStatus
) {
  let iconComponent;
  switch (Number(status)) {
    case 0:
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          style={{ color: 'var(--bs-danger)' }}
          icon={faLock}
        />
      );
      break;
    case 1:
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          style={{ color: 'var(--bs-success)' }}
          icon={faUnlock}
        />
      );
      break;
    default:
      break
  }
  return iconComponent;
}

export function getModuleIcon(role: keyof Permissions) {
  let iconComponent;

  switch (role) {
    case 'dashboard':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faHouse}
          width={15}
        />
      );
      break;
    case 'settings':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faGear}
          width={15}
        />
      );
      break;
    case 'users':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faUser}
          width={15}
        />
      );
      break;
    case 'roles':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faUsers}
          width={15}
        />
      );
      break;
    case 'deals':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faHandshake}
          width={15}
        />
      );
      break;
    case 'participants':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faUserGraduate}
          width={15}
        />
      );
      break;
    case 'educations':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faBoxesStacked}
          width={15}
        />
      );
      break;
    case 'webhooks':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faWebhook}
          width={15}
        />
      );
      break;
    case 'apikeys':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faShieldKeyhole}
          width={15}
        />
      );
      break;
    case 'media':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faImage}
          width={15}
        />
      );
      break;
    case 'satisfactions':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faFaceSmile}
          width={15}
        />
      );
      break;
    case 'activities':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faWavePulse}
          width={15}
        />
      );
      break;
    case 'presences':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faClipboardUser}
          width={15}
        />
      );
      break;
    case 'absences':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faCalendarXmark}
          width={15}
        />
      );
      break;
    default:
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faTags}
          width={15}
        />
      );
      break;
  }

  return iconComponent;
}