import React, { useEffect, useState, FormEvent } from 'react';
import { Modal, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import { ApiClient } from '../../../services/ApiClient';
import { Agenda, Deal } from '../../../interfaces';
import FormGroup from '../../form/FormGroup';
import { formatDateWithoutTime } from '../../../utils/utils';

interface SetActualTimeModalProps {
    onClose: () => void;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
    date: string;
    deal: Deal;
}

/**
 * SetActualTimeModal Component
 *
 * This modal allows users to manually set the actual presence time (IST-Zeit) for a specific day.
 * The time is stored internally in milliseconds but displayed in hours and minutes.
 *
 * Props:
 * - `onClose`: Callback to close the modal.
 * - `onSubmitSuccess`: Callback triggered after a successful submission, with an optional success message.
 * - `date`: The selected date for which the IST-Zeit is being adjusted.
 * - `deal`: The deal object containing `uePerDay`, used to calculate SOLL-Zeit.
 */
const SetActualTimeModal: React.FC<SetActualTimeModalProps> = ({
    onClose,
    onSubmitSuccess,
    date,
    deal,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [hours, setHours] = useState<string>('');
    const [minutes, setMinutes] = useState<string>('');

    useEffect(() => {
        const fetchActualTime = async () => {
            try {
                const response = await ApiClient.get(`/deals/${deal.id}/agenda`);
                const agendaData: Agenda = response.data;

                if (agendaData[date]) {
                    const fetchedActualTimeMs = agendaData[date].actualTime ?? 0;
                    if (fetchedActualTimeMs > 0) {
                        const totalMinutes = Math.round(fetchedActualTimeMs / 60000);
                        setHours(Math.floor(totalMinutes / 60).toString());
                        setMinutes((totalMinutes % 60).toString());
                    }
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der IST-Zeit:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchActualTime();
    }, [deal, date]);

    const calculatedTargetTimeMs = deal.uePerDay * 45 * 60 * 1000;
    const targetMinutes = calculatedTargetTimeMs / 60000;
    const targetHours = Math.floor(targetMinutes / 60);
    const remainingMinutes = targetMinutes % 60;
    const targetTimeFormatted = `${targetHours}h ${remainingMinutes}m`;

    const getActualTimeMs = (): number => {
        return ((parseInt(hours) || 0) * 60 + (parseInt(minutes) || 0)) * 60000;
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        setIsSubmitting(true);
        try {
            const newActualTimeMs = getActualTimeMs();
            await ApiClient.post(`/deals/${deal.id}/setPresenceTime`, { date, actualTime: newActualTimeMs });
            onSubmitSuccess('Erfolgreich gespeichert');
        } catch (error) {
            console.error('Fehler beim Speichern der IST-Zeit:', error);
            onSubmitSuccess('Fehler beim Speichern', true);
        } finally {
            onClose();
            setIsSubmitting(false);
        }
    };

    return (
        <Modal show onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>IST-Zeit manuell setzen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <p>Manuelle Anpassung der IST-Zeit.</p>

                        <FormGroup
                            id="date"
                            label="Tag"
                            value={formatDateWithoutTime(date)}
                            onChange={() => { }}
                            disabled
                        />

                        <FormGroup
                            id="sollZeit"
                            label="SOLL-Zeit"
                            onChange={() => { }}
                            value={targetTimeFormatted}
                            disabled
                        />

                        <Row>
                            <Col>
                                <FormGroup
                                    id="istZeitHours"
                                    label="IST-Zeit (Stunden)"
                                    type="number"
                                    required
                                    value={hours}
                                    onChange={(e) => {
                                        let val = e.target.value;
                                        if (val === "") {
                                            setHours("");
                                        } else {
                                            let numVal = parseInt(val, 10);
                                            if (!isNaN(numVal)) {
                                                numVal = Math.max(0, Math.min(24, numVal));
                                                setHours(numVal.toString());
                                            }
                                        }
                                    }}
                                />
                            </Col>
                            <Col>
                                <FormGroup
                                    id="istZeitMinutes"
                                    label="IST-Zeit (Minuten)"
                                    type="number"
                                    required
                                    value={minutes}
                                    onChange={(e) => {
                                        let val = e.target.value;
                                        if (val === "") {
                                            setMinutes("");
                                        } else {
                                            let numVal = parseInt(val, 10);
                                            if (!isNaN(numVal)) {
                                                numVal = Math.max(0, Math.min(59, numVal));
                                                setMinutes(numVal.toString());
                                            }
                                        }
                                    }}
                                />
                            </Col>
                        </Row>


                        <Row className="mt-4 justify-content-end">
                            <Col xs="auto">
                                <Button onClick={onClose} variant="secondary" className="me-2">
                                    Abbrechen
                                </Button>
                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                    Bestätigen
                                    {isSubmitting && (
                                        <Spinner className="ms-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default SetActualTimeModal;
