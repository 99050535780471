import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faXmark, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { Education } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { useParams } from 'react-router-dom';
import SelectGroup from '../../form/SelectGroup';
import FormGroup from '../../form/FormGroup';
import { getFieldLabel, getFieldOptions } from '../../../utils/utils';

type AddEditEducationModalProps = {
    modalTitle: string;
    education?: Education;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
    onModalClose: () => void;
    fieldConfigs: any[];
};

interface FormValues {
    name: string;
    title: string;
    durationUes: number;
    durationBase: number;
    slug: string;
    lwId_old: string;
    lwUrl_old: string;
    educations: string[];
    course: string;
    module: string;
    unit: string;
    lwId: string;
    lwUrl: string;
    topicQuestions: string[];
    type: number;
    integratedActivities: string[];
    status: number;
    abilities: string[];
}

/**
 * AddEditEducationModal Component
 * 
 * This component renders a modal for adding or editing an education course. 
 * Users can fill out various fields, including arrays of related courses, 
 * integrated activities, and topic questions.
 * 
 * Props:
 * - `modalTitle`: Title displayed in the modal header
 * - `education`: Object containing existing course data (for editing)
 * - `onSubmitSuccess`: Callback triggered on successful submission
 * - `onModalClose`: Callback for closing the modal
 * - `fieldConfigs`: Configuration array for form fields
 */
const AddEditEducationModal: React.FC<AddEditEducationModalProps> = ({
    modalTitle,
    education,
    onSubmitSuccess,
    onModalClose,
    fieldConfigs,
}) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const { educationId } = useParams<{ educationId?: string }>();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);

    const [formValues, setFormValues] = useState<FormValues>({
        name: education?.name ?? '',
        title: education?.title ?? '',
        durationUes: education?.durationUes ?? 0,
        durationBase: education?.durationBase ?? 0,
        slug: education?.slug ?? '',
        lwId_old: education?.lwId_old ?? '',
        lwUrl_old: education?.lwUrl_old ?? '',
        educations: education?.educations ?? [],
        course: education?.course ?? '',
        module: education?.module ?? '',
        unit: education?.unit ?? '',
        lwId: education?.lwId ?? '',
        lwUrl: education?.lwUrl ?? '',
        topicQuestions: education?.topicQuestions ?? [],
        type: education?.type ?? 0,
        integratedActivities: education?.integratedActivities ?? [],
        status: education?.status ?? 0,
        abilities: education?.abilities ?? [],
    });

    const [initialFormValues] = useState<FormValues>(formValues);

    const checkIfDataChanged = (): boolean =>
        JSON.stringify(formValues) !== JSON.stringify(initialFormValues);

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { id, value } = e.target;
        setFormValues((prev) => ({ ...prev, [id]: value }));
    };

    const handleAddItem = (field: keyof FormValues) => {
        setFormValues((prev) => ({
            ...prev,
            [field]: [...(prev[field] as string[]), ''],
        }));
    };

    const handleRemoveItem = (field: keyof FormValues, index: number) => {
        setFormValues((prev) => ({
            ...prev,
            [field]: (prev[field] as string[]).filter((_, i) => i !== index),
        }));
    };

    const handleArrayItemChange = (
        field: keyof FormValues,
        index: number,
        value: string
    ) => {
        const newArray = [...(formValues[field] as string[])];
        newArray[index] = value;
        setFormValues((prev) => ({ ...prev, [field]: newArray }));
    };

    const renderArrayField = (
        field: keyof FormValues,
        label: string,
    ) => (
        <>
            <h6 className="mb-2">{label}</h6>
            {(formValues[field] as string[]).length > 0 ? (
                (formValues[field] as string[]).map((item, index) => (
                    <div key={`${field}-${index}`} className="d-flex align-items-center mb-2">
                        <FormGroup<FormValues>
                            id={field}
                            label={`${index + 1}`}
                            value={item as unknown as FormValues[keyof FormValues]}
                            onChange={(e) =>
                                handleArrayItemChange(field, index, e.target.value)
                            }
                        />
                        <Button
                            variant="danger"
                            size="sm"
                            className="ms-2 mt-3"
                            onClick={() => handleRemoveItem(field, index)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </div>
                ))
            ) : (
                <p className="text-muted">Keine Einträge</p>
            )}
            <Button
                variant="btn btn-soft-primary mb-3"
                size="sm"
                onClick={() => handleAddItem(field)}
            >
                <FontAwesomeIcon icon={faPlus} /> Hinzufügen
            </Button>
        </>
    );

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                const response = educationId
                    ? await ApiClient.put(`/educations/${educationId}`, formValues)
                    : await ApiClient.post('/educations', formValues);

                const newEducationId = response.data.id;
                onSubmitSuccess(newEducationId);
                setFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    return (
        <>
            <Modal
                centered
                show
                onHide={onModalClose}
                backdrop="static"
                keyboard={false}
                fullscreen
            >
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button p-0"
                            >
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}>
                                    <FontAwesomeIcon width={30} icon={faXmark} /> Schließen
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                />
                            )}
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <Row>
                            <Col lg={4}>
                                <h6>Allgemein</h6>
                            </Col>
                            <Col lg={4}>
                                <FormGroup<FormValues>
                                    id="title"
                                    label={getFieldLabel(fieldConfigs, 'educations.title')}
                                    value={formValues.title}
                                    onChange={handleInputChange}
                                />
                                <SelectGroup<FormValues>
                                    id="status"
                                    label={getFieldLabel(fieldConfigs, 'educations.status')}
                                    options={getFieldOptions(fieldConfigs, 'educations.status')}
                                    value={formValues.status}
                                    onChange={handleInputChange}
                                />
                            </Col>
                            <Col lg={4}>
                                <SelectGroup<FormValues>
                                    id="type"
                                    label={getFieldLabel(fieldConfigs, 'educations.type')}
                                    options={getFieldOptions(fieldConfigs, 'educations.type')}
                                    value={formValues.type}
                                    onChange={handleInputChange}
                                />

                            </Col>
                        </Row>

                        <div className="horizontal-line-negative-margin mt-3 mb-3"></div>

                        <Row>
                            <Col lg={4}>
                                <h6>Kursinformationen</h6>
                            </Col>
                            <Col lg={4}>
                                <FormGroup<FormValues>
                                    id="course"
                                    label={getFieldLabel(fieldConfigs, 'educations.course')}
                                    value={formValues.course}
                                    onChange={handleInputChange}
                                />
                                <FormGroup<FormValues>
                                    id="module"
                                    label={getFieldLabel(fieldConfigs, 'educations.module')}
                                    value={formValues.module}
                                    onChange={handleInputChange}
                                />

                                <FormGroup<FormValues>
                                    id="lwId_old"
                                    label={getFieldLabel(fieldConfigs, 'educations.lwId_old')}
                                    value={formValues.lwId_old}
                                    onChange={handleInputChange}
                                />
                            </Col>
                            <Col lg={4}>
                                <FormGroup<FormValues>
                                    id="unit"
                                    label={getFieldLabel(fieldConfigs, 'educations.unit')}
                                    value={formValues.unit}
                                    onChange={handleInputChange}
                                />
                                <FormGroup<FormValues>
                                    id="slug"
                                    label={getFieldLabel(fieldConfigs, 'educations.slug')}
                                    value={formValues.slug}
                                    onChange={handleInputChange}
                                />
                                <FormGroup<FormValues>
                                    id="lwUrl_old"
                                    label={getFieldLabel(fieldConfigs, 'educations.lwUrl_old')}
                                    value={formValues.lwUrl_old}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>

                        <div className="horizontal-line-negative-margin mt-3 mb-3"></div>

                        <Row>
                            <Col lg={4}>
                                <h6>Zeiten</h6>
                            </Col>
                            <Col lg={4}>
                                <FormGroup<FormValues>
                                    id="durationUes"
                                    label={getFieldLabel(fieldConfigs, 'educations.durationUes')}
                                    value={formValues.durationUes.toString()}
                                    onChange={handleInputChange}
                                />
                            </Col>
                            <Col lg={4}>
                                <FormGroup<FormValues>
                                    id="durationBase"
                                    label={getFieldLabel(fieldConfigs, 'educations.durationBase')}
                                    value={formValues.durationBase.toString()}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>

                        <div className="horizontal-line-negative-margin mt-3 mb-3"></div>

                        <Row>
                            <Col lg={4}>
                                <h6>Weiteres</h6>
                            </Col>
                            <Col lg={8}>
                                {
                                    renderArrayField(
                                        'topicQuestions',
                                        'Themen Fragen',
                                    )
                                }
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4}>

                            </Col>
                            <Col lg={4}>
                                {renderArrayField(
                                    'educations',
                                    'Zugehörige Weiterbildungen',

                                )}
                            </Col>
                            <Col lg={4}>
                                {renderArrayField(
                                    'integratedActivities',
                                    'Integrierte Aktivitäten',
                                )}
                            </Col>
                        </Row>

                        <Button type="submit" style={{ display: 'none' }} ref={hiddenSubmitButtonRef} />
                    </Form>
                </Modal.Body>
            </Modal>

            <NotificationToast show={show} onClose={hideToast} message={message} error={error} />
        </>
    );
};

export default AddEditEducationModal;