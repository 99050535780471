import { Activity, Deal, Permissions } from "../interfaces";
import { moduleTranslations } from "./enum";
import { FieldConfig } from '../interfaces';
import { SelectOption } from "../components/form/SelectWithSearch";
import { ApiClient } from "../services/ApiClient";
import { ActivityCounts } from "../components/participants/detail/ActivityBadgeList";

export const formatEuro = (value: number | string) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(Number(value));
};

export const formatToGermanNumber = (value: number) => {
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value);
};

export const formatNumber = (value: string) => {
  return parseFloat(value).toString();
};

export const formatGermanDate = (dateStr: string) => {
  const date = new Date(dateStr);

  if (isNaN(date.getTime())) {
    return null;
  }

  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };
  const formattedDate = new Intl.DateTimeFormat('de-DE', optionsDate).format(
    date
  );

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  const formattedTime = new Intl.DateTimeFormat('de-DE', optionsTime).format(
    date
  );

  return `${formattedDate} - ${formattedTime} Uhr`;
};

export const formatDateWithoutTime = (dateStr: string) => {
  const date = new Date(dateStr);

  if (isNaN(date.getTime())) {
    return dateStr;
  }
  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const formattedDate = new Intl.DateTimeFormat('de-DE', optionsDate).format(
    date
  );

  return formattedDate;
};

export function formatIban(iban: string): string {
  const parts = iban.split(' ');

  if (parts.length <= 2) {
    return iban;
  }

  const firstPart = parts[0];
  const lastPart = parts[parts.length - 1];

  const middleParts = parts.slice(1, parts.length - 1);
  let maskedMiddleParts = middleParts
    .map((part) => 'X'.repeat(part.length))
    .join(' ');

  return `${firstPart} ${maskedMiddleParts} ${lastPart}`;
}

export function normalizeString(str: string) {
  const div = document.createElement('div');
  div.innerHTML = str;
  return div?.textContent!.trim();
}

export function normalizeJsonString(jsonString: string): string {
  const parsedObj = JSON.parse(jsonString);
  const sortedObj = sortObjectKeys(parsedObj);
  return JSON.stringify(sortedObj);
}

function sortObjectKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(sortObjectKeys);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj)
      .sort()
      .reduce((sortedObj, key) => {
        sortedObj[key] = sortObjectKeys(obj[key]);
        return sortedObj;
      }, {} as { [key: string]: any });
  } else {
    return obj;
  }
}

export const generateRandomHex = () => {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join(
    ''
  );
};

export function getModuleList(input: any): string {
  if (input === '*') return '*';

  if (!input) {
    return "-"
  }

  try {
    const translatedKeys = Object.keys(input).map((key) => {
      return moduleTranslations[key as keyof Permissions] || key;
    });
    return translatedKeys.join(', ');
  } catch (error) {
    console.error('Invalid input:', error);
    return '';
  }
}

export function getModuleArray(input: any): { key: string, rights: string[] }[] | string {
  if (!input) return '';

  if (input === '*') return '*';
  try {
    const parsedInput = input ?? '';
    const keys = Object.keys(parsedInput).map(key => ({
      key,
      rights: Object.entries(parsedInput[key]).filter(([, value]) => value).map(([right]) => right)
    }));
    return keys;
  } catch (error) {
    console.error('Invalid input:', error);
    return [];
  }
}

// Extracts the initals from a name. Uses only the first and the last name. Ignores middle names.
export function getInitials(name: string): string {
  if (!name || typeof name !== 'string') {
    return 'U'; // Return "U" for "Unbekannt"
  }

  const nameParts = name.trim().split(' ');
  const filteredNameParts = nameParts.filter(part => part.length > 0);

  if (filteredNameParts.length === 0) {
    return '';
  }

  if (filteredNameParts.length === 1) {
    return filteredNameParts[0][0].toUpperCase();
  }

  const firstInitial = filteredNameParts[0][0]?.toUpperCase() ?? '';
  const lastInitial = filteredNameParts[filteredNameParts.length - 1][0]?.toUpperCase() ?? '';

  return `${firstInitial}${lastInitial}`;
}

export const parseDate = (dateStr: string) => {
  const [day, month, year] = dateStr.split('.').map(Number);
  return new Date(year, month - 1, day);
};

export const calculateProgressFromStartAndEndDate = (start: string, end: string, abortDate: string): number => {
  const startDate = parseDate(start);
  const endDate = parseDate(end);
  const today = new Date();

  // Parse abort date if valid, else set to null
  const abort = abortDate && abortDate !== '0000-00-00 00:00:00' ? parseDate(abortDate) : null;

  // Calculate the total duration from the start to the original end date (in days)
  const totalDuration = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

  // Determine the effective current date for the progress calculation
  let effectiveCurrentDate = today;
  if (abort && abort.getTime() < today.getTime()) {
    effectiveCurrentDate = abort; // Stop progress at abort date if in the past
  }

  // Calculate elapsed time from the start date to today or the abort date (in days)
  const elapsedDuration = (effectiveCurrentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

  // If the elapsed time is less than 0 (i.e., we are before the start date), return 0% progress
  if (elapsedDuration < 0) {
    return 0;
  }

  // If the elapsed time exceeds the total duration, cap the progress at 100%
  if (elapsedDuration >= totalDuration) {
    return 100;
  }

  // Calculate progress as a percentage of the total duration
  return Math.round((elapsedDuration / totalDuration) * 100);
};

export function getDealLabel(startDate: string, isCurrentDeal: boolean): string {
  const date = new Date(startDate);
  const month = date.toLocaleString('de-DE', { month: 'short' });
  const year = date.getFullYear();
  return isCurrentDeal ? `Aktueller Deal ${month}. ${year}` : `Deal ${month}. ${year}`;
}

/**
 * Formats a date string into a specified format.
 * 
 * The function replaces placeholders in the format string with the corresponding
 * date components. Text enclosed in square brackets (e.g., `[literal text]`) is 
 * treated as a literal and returned as-is.
 * 
 * Supported placeholders:
 * - Y: Full year (e.g., 2024)
 * - yy: Two-digit year (e.g., 24)
 * - m: Month with leading zero (01-12)
 * - mm: Month with leading zero (01-12)
 * - d: Day with leading zero (01-31)
 * - dd: Day with leading zero (01-31)
 * - H: Hours with leading zero (00-23)
 * - i: Minutes with leading zero (00-59)
 * - s: Seconds with leading zero (00-59)
 * 
 * @param dateString - The input date string to format.
 * @param format - The desired format string, including placeholders and literals.
 * @returns The formatted date string.
 */
export function formatDate(dateString: string, format: string) {
  const date = new Date(dateString);

  const padZero = (number: number) => (number < 10 ? '0' : '') + number;

  const replacements: any = {
    Y: date.getFullYear(),
    yy: String(date.getFullYear()).slice(-2),
    m: padZero(date.getMonth() + 1),
    mm: padZero(date.getMonth() + 1),
    d: padZero(date.getDate()),
    dd: padZero(date.getDate()),
    H: padZero(date.getHours()),
    i: padZero(date.getMinutes()),
    s: padZero(date.getSeconds()),
  };

  return format.replace(/\[.*?\]|Y|yy|mm|m|dd|d|H|i|s/g, match => {
    if (match.startsWith('[') && match.endsWith(']')) {
      return match.slice(1, -1); // Return literals as-is
    }
    return replacements[match] || match; // Replace placeholders with values
  });
}

export function formatApiKey(str: string) {
  if (!str) {
    return ''
  }
  if (str.length <= 10) {
    return str;
  }
  return str.slice(0, 10) + '**********';
}

/**
 * Retrieves a specific field configuration object by its resource name.
 * 
 * This function searches through an array of `FieldConfig` objects to find
 * a configuration that matches the given `resourceName`.
 * 
 * @param {FieldConfig[] | undefined} fieldConfigs - An array of field configuration objects.
 *   It may be undefined or empty.
 * @param {string} resourceName - The resource name to look for within the field configurations.
 * 
 * @returns {FieldConfig} - The matching `FieldConfig` object if found, or an empty object if not.
 *   The function casts the empty object as `FieldConfig` for consistency in return type.
 */
export const getFieldConfigByResourceName = (
  fieldConfigs: FieldConfig[] | undefined,
  resourceName: string
) => {
  if (!fieldConfigs || fieldConfigs.length === 0) {
    return {} as FieldConfig;
  }
  return fieldConfigs.find((config) => config.resourceName === resourceName);
};

/**
 * Retrieves select options for a given resource name from field configurations.
 * 
 * This function extracts options from a `FieldConfig` object that matches
 * the provided `resourceName` within the array of `FieldConfig` objects.
 * It converts the options object into an array of `SelectOption` objects,
 * which contain `value` and `label` pairs suitable for use in a dropdown.
 * 
 * @param {FieldConfig[]} fieldConfigs - An array of field configuration objects.
 * @param {string} resourceName - The resource name to look for within the field configurations.
 * 
 * @returns {SelectOption[]} - An array of `SelectOption` objects where each object
 *   represents a value-label pair from the matching `FieldConfig` options.
 *   Returns an empty array if no matching field configuration or options are found.
 */
export const getFieldOptions = (
  fieldConfigs: FieldConfig[],
  resourceName: string
): SelectOption[] => {
  const options = getFieldConfigByResourceName(fieldConfigs, resourceName)?.options ?? {};
  return Object.entries(options).map(([value, label]) => ({ value, label }));
};

/**
 * Retrieves the label for a given resource name from field configurations.
 * 
 * This function finds a `FieldConfig` object that matches the provided `resourceName`
 * within the array of `FieldConfig` objects and extracts its `fieldLabel`.
 * 
 * @param {FieldConfig[]} fieldConfigs - An array of field configuration objects.
 * @param {string} resourceName - The resource name to look for within the field configurations.
 * 
 * @returns {string} - The `fieldLabel` of the matching `FieldConfig` object.
 *   Returns an empty string if no matching configuration is found.
 */
export const getFieldLabel = (
  fieldConfigs: FieldConfig[],
  resourceName: string
): string => {
  return getFieldConfigByResourceName(fieldConfigs, resourceName)?.fieldLabel || '';
};

/**
 * Adds a specified prefix to each filter in an array of filter strings.
 * 
 * This function ensures that each filter string includes a prefix.
 * If a filter already contains a dot (.), indicating that it already has a prefix,
 * it is returned as-is. Otherwise, the specified prefix is prepended to the filter.
 * 
 * @param {string[]} filters - An array of filter strings to which the prefix will be applied.
 * @param {string} prefix - The prefix to add to each filter string.
 * 
 * @returns {string[]} - A new array of filter strings with the prefix applied where necessary.
 *   Filters that already contain a prefix are not modified.
 */
export function addPrefixToFilters(filters: string[], prefix: string) {
  return filters.map((filter) => {
    return filter.includes('.') ? filter : `${prefix}.${filter}`;
  });
}

/**
 * Formats a `Deal` object into a string representation that includes the month and year
 * of the deal's start date.
 * 
 * Example output: "Deal Oktober 2023"
 * 
 * @param {Deal} deal - The deal object containing the `start` date.
 * @returns {string} - A formatted string with the deal's start month and year, or an empty string if no start date is provided.
 */
export function formatDealMonthYear(deal: Deal): string {
  if (!deal.start) {
    return '';
  }
  const startDate = new Date(deal.start);

  const monthNames = [
    'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
    'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
  ];

  const month = monthNames[startDate.getMonth()];
  const year = startDate.getFullYear();
  return `Deal ${month} ${year}`;
}

/**
 * groupActivitiesByMonthAndYear Function
 * 
 * Groups a list of activities by their creation date, using the month and year as the grouping key.
 * This function first sorts activities in descending order by date, so the most recent month appears first.
 * It then formats the date in "MMMM YYYY" (e.g., "October 2024") and organizes activities 
 * into an object where each key is a formatted month-year string and each value is an array of activities 
 * created during that month.
 * 
 * @param {Activity[]} activities - An array of activity objects, each containing a `timeOfActivity` date property.
 * @returns {Record<string, Activity[]>} An object where each key is a "MMMM YYYY" formatted date string,
 * and each value is an array of activities for that month, with the most recent month appearing at the top.
 * 
 * Example Usage:
 * ```typescript
 * const activities = [
 *   { timeOfActivity: "2024-10-01T12:34:56Z", ... },
 *   { timeOfActivity: "2024-10-15T08:00:00Z", ... },
 *   { timeOfActivity: "2024-11-01T09:00:00Z", ... }
 * ];
 * const grouped = groupActivitiesByMonthAndYear(activities);
 * // Result:
 * // {
 * //   "November 2024": [{...}],
 * //   "October 2024": [{...}, {...}]
 * // }
 * ```
 */
export const groupActivitiesByMonthAndYear = (activities: Activity[]) => {
  const sortedActivities = activities.sort((a, b) => new Date(b.timeOfActivity).getTime() - new Date(a.timeOfActivity).getTime());

  return sortedActivities.reduce((acc, activity) => {
    const date = new Date(activity.timeOfActivity);
    const monthYear = date.toLocaleDateString('de-DE', { year: 'numeric', month: 'long' });
    if (!acc[monthYear]) acc[monthYear] = [];
    acc[monthYear].push(activity);
    return acc;
  }, {} as Record<string, Activity[]>);
};

/**
 * Fetches and combines field configurations from multiple endpoints.
 *
 * This function accepts an array of endpoints, fetches the field configurations
 * from each endpoint concurrently, combines all configurations into a single array,
 * and updates the provided state setter with the combined configurations.
 *
 * The `_name` field is always fetched and saved into a state if provided.
 *
 * @param endpoints - An array of endpoint strings to fetch field configurations from.
 * @param setFieldConfigs - A state setter function to update the field configurations in the component state.
 */
export const fetchAndCombineFieldConfigs = async (
  endpoints: string[],
  setFieldConfigs: React.Dispatch<React.SetStateAction<FieldConfig[]>>,
) => {
  try {
    const responses = await Promise.all(
      endpoints.map((endpoint) => ApiClient.get(`/${endpoint}/columns`))
    );
    const combinedFieldConfigs = responses.flatMap((response) => {
      const data = response.data;
      return Object.keys(data)
        .filter((key) => key !== "_name")
        .flatMap((key) => {
          const fieldConfigGroup = data[key];
          return fieldConfigGroup ? Object.values(fieldConfigGroup) : [];
        });
    });
    setFieldConfigs(combinedFieldConfigs as FieldConfig[]);
  } catch (error) {
    console.error("Error fetching field configurations:", error);
  }
};

/**
 * Generates a cluster configuration for activity types, grouping them into predefined clusters
 * and dynamically identifying unclustered activity types.
 * 
 * The function uses `activityCounts` to calculate the number of activities for each type
 * and organizes them into clusters based on the `clusterDefinitions`. Any activity types
 * not explicitly defined in `clusterDefinitions` are automatically grouped under the "Weitere" cluster.
 * 
 * @param activityCounts - Array of activity counts, where each object contains:
 *   - `type` (number): The unique identifier for the activity type.
 *   - `count` (number): The count of occurrences for this activity type.
 * 
 * @returns An array of clusters, where each cluster contains:
 *   - `clusterName` (string): The name of the cluster (e.g., "Schreiben").
 *   - `filters` (array): A list of filters for the cluster, each containing:
 *      - `type` (number): The activity type identifier.
 *      - `count` (number): The count of occurrences for the activity type.
 * 
 * Example:
 * Input:
 *   activityCounts = [
 *     { type: 0, count: 5 },
 *     { type: 3, count: 2 },
 *     { type: 10, count: 1 }
 *   ]
 * 
 * Output:
 *   [
 *     {
 *       clusterName: 'Schreiben',
 *       filters: [
 *         { type: 0, count: 5 },
 *         { type: 1, count: 0 },
 *         { type: 9, count: 0 }
 *       ]
 *     },
 *     {
 *       clusterName: 'Kommunikation',
 *       filters: [
 *         { type: 2, count: 0 },
 *         { type: 3, count: 2 }
 *       ]
 *     },
 *     {
 *       clusterName: 'Weitere',
 *       filters: [
 *         { type: 10, count: 1 }
 *       ]
 *     }
 *   ]
 */
export const getClusterConfigWithCounts = (activityCounts: ActivityCounts[]) => {
  const countMap = Object.fromEntries(activityCounts.map((item) => [item.type, item.count]));

  const clusterDefinitions = {
    Schreiben: [0, 1, 9], // Notiz, Klassenbucheintrag, HubSpot Notiz
    Kommunikation: [2, 3, 12], // Telefonate, Expertengespräch, Intercom
    Zufriedenheit: [4, 5, 6], // Unsere Teilnehmerbewertung, Abmahnung, Feedbacks vom Teilnehmer
    Lernwelt: [7, 8, 10, 11, 13, 14, 15], // Lernfortschritt, Wissenstest, Modul gesperrt, Modul entsperrt
  };

  const clusters = Object.entries(clusterDefinitions).map(([clusterName, types]) => ({
    clusterName,
    filters: types.map((type) => ({ type, count: countMap[type] || 0 })),
  }));

  const clusteredTypes = Object.values(clusterDefinitions).flat();
  const unclusteredFilters = activityCounts
    .filter((activity) => !clusteredTypes.includes(activity.type))
    .map((activity) => ({ type: activity.type, count: activity.count }));

  if (unclusteredFilters.length > 0) {
    clusters.push({
      clusterName: 'Weitere',
      filters: unclusteredFilters,
    });
  }

  return clusters;
};

/**
 * Calculates the expiration details of a deal based on its start date and duration.
 * 
 * @param dealStart - The start date of the deal in string format (e.g., ISO date string).
 * @param daysUntilExpiration - The number of days the deal is valid after the start date.
 * @returns An object containing:
 *   - `dayCount`: The number of days remaining until the deal expires.
 *   - `isActive`: A boolean indicating whether the deal is currently active.
 *   - `expirationDate`: The exact expiration date of the deal.
 */
export function getDealExpirationDetails(
  dealStart: string,
  daysUntilExpiration: number = 14
): { dayCount: number; isActive: boolean; expirationDate: Date } {
  const dealStartDate = new Date(dealStart);
  const expirationDate = new Date(dealStartDate);
  expirationDate.setDate(dealStartDate.getDate() + daysUntilExpiration);

  const today = new Date();
  const timeDifference = expirationDate.getTime() - today.getTime();
  const dayCount = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  const isActive = today >= dealStartDate && today <= expirationDate;

  return { dayCount, isActive, expirationDate };
}

/**
 * Formats a given duration in seconds into a human-readable string.
 * - If less than 60 seconds → "xx Sek."
 * - If less than 60 minutes → "xx Min. xx Sek."
 * - If 1 hour or more → "xx Std. xx Min."
 *
 * @param {number} seconds - The duration in seconds to be formatted.
 * @returns {string} - A formatted string in the appropriate time unit.
 */
export function formatDuration(seconds: number): string {
  if (seconds < 60) {
      return `${seconds} Sek.`;
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (minutes < 60) {
      return remainingSeconds > 0
          ? `${minutes} Min. ${remainingSeconds} Sek.`
          : `${minutes} Min.`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return remainingMinutes > 0
      ? `${hours} Std. ${remainingMinutes} Min.`
      : `${hours} Std.`;
}