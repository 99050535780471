import { Fragment, useEffect, useState } from "react";
import { usePermissions } from "../../hooks/usePermissions";
import Card from "../../components/bootstrap/card";
import { Col, Dropdown, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import { ApiClient } from "../../services/ApiClient";

interface DealData {
  year: number;
  month: number;
  ongoing_deals?: number;
  starting_deals?: number;
  deals_ended?: number;
  deals_aborted?: number;
}

interface Stats {
  ongoingDeals: DealData[];
  startingDeals: DealData[];
  finishedDeals: DealData[];
}

const getVariableColor = () => {
  let prefix =
    getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
  if (prefix) {
    prefix = prefix.trim();
  }
  const color1 = getComputedStyle(document.body).getPropertyValue(
    `--${prefix}primary`
  );
  const color2 = getComputedStyle(document.body).getPropertyValue(
    `--${prefix}info`
  );
  const color3 = getComputedStyle(document.body).getPropertyValue(
    `--${prefix}primary-tint-20`
  );
  const color4 = getComputedStyle(document.body).getPropertyValue(
    `--${prefix}warning`
  );
  const color5 = getComputedStyle(document.body).getPropertyValue(
    `--${prefix}danger`
  );
  const color6 = getComputedStyle(document.body).getPropertyValue(
    `--${prefix}success`
  );
  return {
    primary: color1.trim(),
    info: color2.trim(),
    warning: color4.trim(),
    primary_light: color3.trim(),
    danger: color5.trim(),
    success: color6.trim()
  };
};

const variableColors = getVariableColor();

const GERMAN_MONTHS = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];
const monthOptions = [3, 6, 12, 18, 24, 48];

const Index = () => {
  const { permissionsLoaded } = usePermissions();
  const [selectedMonths, setSelectedMonths] = useState(12);
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState<Stats>({
    ongoingDeals: [],
    startingDeals: [],
    finishedDeals: [],
  });

  useEffect(() => {
    if (permissionsLoaded) {
      fetchStats();
    }
  }, [permissionsLoaded, selectedMonths]);

  const fetchStats = async () => {
    setIsLoading(true);
    try {
      const res = await ApiClient.get(`/dashboard/standard/${selectedMonths}`);
      setStats(res.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching stats:", error);
      setIsLoading(false);
    }
  };

  const getFormattedDate = (startOfMonth: boolean = false): string => {
    const today = new Date();
    const day = startOfMonth ? "01" : String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${day}.${month}.${year}`;
  }

  const getLatestDealData = (deals: DealData[]) => {
    return deals.reduce((latest, deal) => {
      return !latest || deal.year > latest.year || (deal.year === latest.year && deal.month > latest.month) ? deal : latest;
    }, deals[0]);
  };

  const processDeals = (deals: DealData[], valueKey: keyof DealData, highlightColor: string, defaultColor: string) => {
    const latestDeal = getLatestDealData(deals);
    return deals.map((deal) => ({
      x: `${GERMAN_MONTHS[deal.month - 1]} ${deal.year}`,
      y: deal[valueKey] || 0,
      color: deal === latestDeal ? highlightColor : defaultColor,
    }));
  };

  const processActiveDeals = () => processDeals(stats.ongoingDeals, "ongoing_deals", variableColors.warning, variableColors.primary);
  const processPreparedDeals = () => processDeals(stats.startingDeals, "starting_deals", variableColors.info, variableColors.primary);

  const latestActiveDeal = getLatestDealData(stats.ongoingDeals);
  const latestPreparedDeal = getLatestDealData(stats.startingDeals);
  const latestFinishedDeal = getLatestDealData(stats.finishedDeals);

  const processFinishedDeals = () => {
    return stats.finishedDeals.map((deal) => ({
      x: `${GERMAN_MONTHS[deal.month - 1]} ${deal.year}`,
      y: (deal.deals_ended || 0) + (deal.deals_aborted || 0),
      aborted: deal.deals_aborted || 0,
      success: deal.deals_ended || 0,
    }));
  };

  const chartOptions = (categories: string[], type?: "finishedDeals") => ({
    chart: {
      type: "bar",
      stacked: true,
      toolbar: { show: false },
    },
    xaxis: {
      categories,
      labels: {
        style: { colors: "#8A92A6" },
      },
    },
    yaxis: {
      labels: {
        style: { colors: "#8A92A6" },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        endingShape: "rounded",
      },
    },
    fill: {
      opacity: 1,
    },
    dataLabels: { enabled: false },
    colors: type === "finishedDeals"
      ? [variableColors.danger, variableColors.success]
      : undefined,
  });

  const sumDeals = (deals: any[], key: "deals_aborted" | "deals_ended") => {
    return deals.reduce((sum, deal) => sum + (deal[key] || 0), 0);
  };

  const chartSeries = (data: any[], type?: "finishedDeals") => {
    if (type === "finishedDeals") {
      return [
        {
          name: `Abgebrochene Deals (${sumDeals(stats.finishedDeals, "deals_aborted")})`,
          data: data.map((d) => ({
            x: d.x,
            y: d.aborted,
            fillColor: variableColors.danger,
          })),
        },
        {
          name: `Erfolgreiche Deals (${sumDeals(stats.finishedDeals, "deals_ended")})`,
          data: data.map((d) => ({
            x: d.x,
            y: d.success,
            fillColor: variableColors.success,
          })),
        },
      ];;
    }

    return [
      {
        name: "Deals",
        data: data.map((d) => ({
          x: d.x,
          y: d.y,
          fillColor: d.color,
        })),
      },
    ];
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3>Dashboard</h3>
        </div>
        {/* Dropdown for selecting months */}
        <Dropdown>
          <Dropdown.Toggle className="btn-soft-primary">
            Vergangene {selectedMonths} Monate
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {monthOptions.map((months) => (
              <Dropdown.Item key={months} onClick={() => setSelectedMonths(months)}>
                Vergangene {months} Monate
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Row>
        <Col lg={4}>
          <Card data-aos="fade-up" data-aos-delay="1000">
            <div className="flex-wrap card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-2">Laufende Deals</h4>
                <div className="d-flex align-items-center">
                  <h2 className="mb-0 me-2">{latestActiveDeal?.ongoing_deals || 0}</h2>
                  <span className="">
                    Aktueller Monat<br /> <small>{getFormattedDate(true)} bis {getFormattedDate()}</small>
                  </span>
                </div>
              </div>
            </div>
            <Card.Body>
              <Chart
                options={chartOptions(
                  stats.ongoingDeals.map((d) => `${GERMAN_MONTHS[d.month - 1]} ${d.year}`)
                ) as ApexCharts.ApexOptions}
                series={chartSeries(processActiveDeals())}
                type="bar"
                height="230"
              />
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4}>
          <Card data-aos="fade-up" data-aos-delay="1000">
            <div className="flex-wrap card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-2">Startende Deals</h4>
                <div className="d-flex align-items-center">
                  <h2 className="mb-0 me-2">{latestPreparedDeal?.starting_deals || 0}</h2>
                  <span className="">
                    Aktueller Monat
                  </span>
                </div>
              </div>
            </div>
            <Card.Body>
              <Chart
                options={chartOptions(
                  stats.startingDeals.map((d) => `${GERMAN_MONTHS[d.month - 1]} ${d.year}`),
                ) as ApexCharts.ApexOptions}
                series={chartSeries(processPreparedDeals())}
                type="bar"
                height="230"
              />
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4}>
          <Card data-aos="fade-up" data-aos-delay="1000">
            <div className="flex-wrap card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-2">Beendete Deals</h4>
                <div className="d-flex align-items-center">
                  <h2 className="mb-0 me-2">{latestFinishedDeal?.deals_ended || 0}</h2>
                  <span className="">
                    Aktueller Monat<br /> <small>{getFormattedDate(true)} bis {getFormattedDate()}</small>
                  </span>
                </div>
              </div>
            </div>
            <Card.Body>
              <Chart
                options={chartOptions(
                  stats.finishedDeals.map((d) => `${GERMAN_MONTHS[d.month - 1]} ${d.year}`),
                  "finishedDeals"
                ) as ApexCharts.ApexOptions}
                series={chartSeries(processFinishedDeals(), "finishedDeals")}
                type="bar"
                height="230"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Index;