import React from 'react';
import { Row, Col, Table, Image } from 'react-bootstrap';
import { Presence } from '../../../interfaces';
import SkeletonCard from '../../skeleton/SkeletonCard';
import GenericInfosCard from '../../GenericInfosCard';
import { ApiClient } from '../../../services/ApiClient';
import StatusDropdownButton from '../../StatusDropdownButton';
import { presencesAbsencesStateColorMap, presencesAbsencesStateIconMap, presencesStatusColorMap } from '../../filter/iconAndColorMappings';
import { formatDateWithoutTime } from '../../../utils/utils';
import { getEnumValue, PresencesAbsenceStateEnum, PresencesStatusEnum } from '../../../utils/enum';
import Card from '../../bootstrap/card';
import { getMediaUrl } from '../../../utils/media/utils';

interface PresenceDetailsProps {
    isLoading?: boolean;
    presence?: Presence | null;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
}

/**
 * PresenceDetails Component
 *
 * This component displays detailed information about a specific presence record.
 * It includes:
 * - General presence information in two info cards.
 * - A dropdown to change the presence state.
 * - A table displaying daily presence details for the week.
 *
 * Props:
 * - `isLoading` (boolean) - Displays skeleton loaders when data is loading.
 * - `presence` (Presence | null) - The presence data object.
 * - `onSubmitSuccess` (function) - Callback function triggered after a successful state update.
 */
const PresenceDetails: React.FC<PresenceDetailsProps> = ({
    isLoading,
    presence,
    onSubmitSuccess
}) => {
    const handleStateChange = async (state: number) => {
        if (!presence) return;

        try {
            await ApiClient.put(`/presences/${presence.id}`, { state });
            onSubmitSuccess('Erfolgreich gespeichert');
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    function getInfo1() {
        return [
            {
                label: 'Titel',
                value: presence?.title || '-',
            },
            {
                label: 'Jahr',
                value: presence?.year || '-',
            },
            {
                label: 'Kalenderwoche',
                value: presence?.calendarWeek || '-',
            },
            {
                label: 'Start',
                value: presence?.start ? formatDateWithoutTime(presence.start) : '-',
            },
            {
                label: 'Ende',
                value: presence?.end ? formatDateWithoutTime(presence.end) : '-',
            },
            {
                label: 'State',
                value: getEnumValue(PresencesAbsenceStateEnum, presence?.state.toString()),
            },
            {
                label: 'Notizen',
                value: presence?.notes || '-',
            },
        ];
    }

    return (
        <>
            {/* Title and Status Dropdown */}
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{presence?.title}</h4>
                {presence && (
                    <StatusDropdownButton
                        disabled={presence.state === 1 || presence.state === 2}
                        status={presence.state}
                        onStatusChange={handleStateChange}
                        statusColorMap={presencesAbsencesStateColorMap}
                        statusIconMap={presencesAbsencesStateIconMap}
                        filterEnum={PresencesAbsenceStateEnum}
                    />
                )}
            </div>

            {/* Info Cards */}
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : <Card className="card-block card-stretch card-height">
                        <Card.Body>
                            <h5>Unterschrift</h5>
                            {presence?.signature ? (
                                <Image
                                    src={getMediaUrl(presence.signature)}
                                    alt="Unterschrift"
                                    fluid
                                    className="border p-2"
                                />
                            ) : (
                                '-'
                            )}
                        </Card.Body>
                    </Card>}
                </Col>
            </Row>

            {/* Table for Weekly Data */}
            <h5 className="mt-4">Wochendetails</h5>
            <Table responsive className="mt-3">
                <thead>
                    <tr>
                        <th>Tag</th>
                        <th>Datum</th>
                        <th>Soll-Zeit</th>
                        <th>Ist-Zeit</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {presence &&
                        Object.entries(presence.week).map(([dayKey, dayData]) => (
                            <tr key={dayKey}>
                                <td>{dayData.label}</td>
                                <td>{dayData.date}</td>
                                <td>{dayData.targetTime} Std.</td>
                                <td>{dayData.actualTime} Std.</td>
                                <td className={presencesStatusColorMap[dayData.status]}>{getEnumValue(PresencesStatusEnum, dayData?.status.toString())}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </>
    );
};

export default PresenceDetails;
