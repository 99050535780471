import React, { useState } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCircleCheck, faDownload, faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import PortalWrapper from '../../PortalWrapper';
import SwitchGroup from '../../form/SwitchGroup';
import InfoDialog from '../../InfoDialog';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { ApiClient } from '../../../services/ApiClient';

type CalendarToolbarEngagementsProps = {
  viewMode: 'calendar' | 'list';
  onViewModeChange: (value: 'calendar' | 'list') => void;
  onPrevClick: () => void;
  onNextClick: () => void;
  onTodayClick: () => void;
  dealId: number
};

/**
 * CalendarToolbarEngagements Component
 *
 * This component renders a toolbar for navigating and managing a calendar view.
 * It includes functionality for navigating between previous, current, and next views, 
 * switching between "calendar" and "list" modes, and downloading engagement data.
 *
 * Props:
 * - `viewMode` (string): The current view mode, either "calendar" or "list".
 * - `onViewModeChange` (function): Callback function triggered when the view mode is changed.
 * - `onPrevClick` (function): Callback function triggered when the "Previous" button is clicked.
 * - `onNextClick` (function): Callback function triggered when the "Next" button is clicked.
 * - `onTodayClick` (function): Callback function triggered when the "Today" button is clicked.
 * - `dealId` (number): ID of the deal.
 */
const CalendarToolbarEngagements: React.FC<CalendarToolbarEngagementsProps> = ({
  viewMode,
  onViewModeChange,
  onPrevClick,
  onNextClick,
  onTodayClick,
  dealId
}) => {
  const { show, message, error, hideToast, showToast } = useToast();
  const [dialogContent, setDialogContent] = useState<{ headline: string; message: string } | null>(null);

  const handleEngagementsDownload = async () => {
    try {
      const response = await ApiClient.post(`/deals/${dealId}/engagements/generate`, {});
      if (response.data.type === 'toast') {
        setDialogContent({
          headline: response.data.headline,
          message: response.data.message,
        });

        setTimeout(() => {
          setDialogContent(null);
        }, 7000);

      } else {
        showToast(`${response.data.headline}: ${response.data.message}`);
      }
    } catch (error: any) {
      showToast(error.response.data[1] ?? 'Fehler beim Erstellen der PDF', true);
      console.error('Download failed:', error);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex gap-1">
          <Button disabled={!!dialogContent} className="px-3 btn-soft-primary" variant="secondary" onClick={handleEngagementsDownload}>
            <FontAwesomeIcon icon={faDownload} />
          </Button>

          {/* <SwitchGroup
          label=""
          value={viewMode}
          onChange={(value) => onViewModeChange(value as 'calendar' | 'list')}
          options={[
            { value: 'calendar', label: 'Kalender' },
            { value: 'list', label: 'Liste' },
          ]}
        />

        <Button className="btn-soft-primary px-3" variant="secondary" onClick={onPrevClick}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <Button variant="primary" onClick={onTodayClick}>
          Heute
        </Button>
        <Button className="btn-soft-primary px-3" variant="secondary" onClick={onNextClick}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button> */}
        </div>
      </div>
      {dialogContent && (
        <InfoDialog onClose={() => setDialogContent(null)} title={dialogContent.headline}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="text-black text-break">{dialogContent.message}</span>
            <FontAwesomeIcon icon={faCircleCheck} className="text-success ms-2" size="lg" />
          </div>
        </InfoDialog>
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default CalendarToolbarEngagements;
