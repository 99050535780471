import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useSaveLastUrl } from '../hooks/useSaveLastUrl';
import { CompanyEnum } from '../utils/enum';
import { usePermissions } from '../hooks/usePermissions';
import { useEffect } from 'react';

const requiredTokenProperties = [
  'iss',
  'aud',
  'iat',
  'exp',
  'viaToken',
  'user_id',
  'roles_id',
  'views',
];

const isAuthenticated = () => {
  const companyId = sessionStorage.getItem('companyId') || 'oc';
  const token = localStorage.getItem(`accessToken_${companyId}`);
  if (!token) return false;

  try {
    const decodedToken = jwtDecode(token);
    const hasAllRequiredProps = requiredTokenProperties.every(prop => decodedToken.hasOwnProperty(prop));
    const decodedTokenProps = Object.keys(decodedToken);
    const hasExactProps = decodedTokenProps.length === requiredTokenProperties.length && hasAllRequiredProps;

    const currentTime = Date.now() / 1000;
    if (!hasExactProps || decodedToken.exp! < currentTime) {
      return false;
    }
    return true;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
};

const ProtectedRoute = () => {
  useSaveLastUrl();
  const navigate = useNavigate();
  const { userHasCompanyAccess, permissionsLoaded } = usePermissions();
  let { companyId = 'oc' } = useParams<{ companyId?: keyof typeof CompanyEnum }>();

  useEffect(() => {
    if (permissionsLoaded && !userHasCompanyAccess(companyId)) {
      navigate('/errors/error404');
    }
  }, [companyId, navigate, userHasCompanyAccess, permissionsLoaded]);
  return isAuthenticated() ? <Outlet /> : <Navigate to={`/${companyId}/login`} />;
};

export default ProtectedRoute;
