import React, { FormEvent, useRef, useState } from 'react';
import { Modal, Button, Row, Col, Dropdown, Spinner, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import ComboButtonGroup, { ComboButtonId } from '../../ComboButtonGroup';
import SelectGroup from '../../form/SelectGroup';
import FormGroup from '../../form/FormGroup';
import { Deal, FieldConfig } from '../../../interfaces';
import { formatDate, getFieldOptions } from '../../../utils/utils';

type AddEditDealProps = {
  modalTitle: string;
  deal?: Deal;
  onSubmitSuccess: (message?: string, isError?: boolean) => void;
  onModalClose: () => void;
  fieldConfigs: FieldConfig[];
};

interface FormValues {
  offerNumber: string;
  offerDate: string;
  macbook: boolean;
  offerTitle: string;
  actionNumber: string;
  jamf: string;
  hubspotDealIdLink: string;
  bgsnumber: string;
  status: number;
  start: string;
  end: string;
  extendedEnd: string;
  ue: number;
  durationWeeks: number;
  timeModel: string;
  uePerDay: number;
  durationMonths: number;
  title: string;
  priceTotalGross: string;
  priceTax: string;
  selfPay: boolean;
  priceTotalNet: string;
  psmIncludet: boolean;
  externalTest: boolean;
  externalReg: boolean;
  externalWhere: string;
  externalWhen: string;
  abort: boolean;
  abortDate: string;
  abortReason: string | null;
  abortNotice: string;
  contractLocation: string
  degreeDesignation: string
}

const comboButtons = [
  { id: 'general', label: 'Allgemeines' },
  { id: 'times', label: 'Zeiten' },
  { id: 'finances', label: 'Finanzen' },
  { id: 'wv', label: 'WV' },
  { id: 'audit', label: 'Prüfung' },
  { id: 'abort', label: 'Abbruch' },
];

/**
 * AddEditDealModal Component
 * 
 * This component is a modal for adding or editing a deal. It includes form inputs for 
 * general information, timing details, financial details, audit details, and abort information.
 * The form dynamically switches sections using a combo button group.
 * 
 * Props:
 * - modalTitle: The title of the modal.
 * - deal: The deal object to be edited, or undefined if creating a new deal.
 * - onSubmitSuccess: Callback function triggered upon successful submission of the form.
 * - onModalClose: Callback to close the modal.
 * - fieldConfigs: Array of field configurations for dynamic form options.
 */

const AddEditDealModal: React.FC<AddEditDealProps> = ({
  modalTitle,
  deal,
  onSubmitSuccess,
  onModalClose,
  fieldConfigs
}) => {
  const [formValues, setFormValues] = useState<FormValues>({
    offerNumber: deal?.offerNumber ?? '',
    offerDate: deal?.offerDate ? formatDate(deal?.offerDate, 'Y-m-d') : '',
    macbook: deal?.macbook ?? false,
    offerTitle: deal?.offerTitle ?? '',
    actionNumber: deal?.actionNumber ?? '',
    jamf: deal?.jamf ?? '',
    hubspotDealIdLink: deal?.hubspotDealIdLink ?? '',
    bgsnumber: deal?.bgsnumber ?? '',
    status: deal?.status ?? 0,
    start: deal?.start ? formatDate(deal?.start, 'Y-m-d') : '',
    end: deal?.end ? formatDate(deal?.end, 'Y-m-d') : '',
    extendedEnd: deal?.extendedEnd ? formatDate(deal?.extendedEnd, 'Y-m-d') : '',
    ue: deal?.ue ?? 0,
    durationWeeks: deal?.durationWeeks ?? 0,
    timeModel: deal?.timeModel ?? '',
    uePerDay: deal?.uePerDay ?? 0,
    durationMonths: deal?.durationMonths ?? 0,
    title: deal?.title ?? '',
    priceTotalGross: deal?.priceTotalGross ? deal.priceTotalGross.toString().replace('.', ',') : '',
    priceTax: deal?.priceTax ? deal.priceTax.toString().replace('.', ',') : '',
    selfPay: deal?.selfPay ?? false,
    priceTotalNet: deal?.priceTotalNet ? deal.priceTotalNet.toString().replace('.', ',') : '',
    psmIncludet: deal?.psmIncludet ?? false,
    externalTest: deal?.externalTest ?? false,
    externalReg: deal?.externalReg ?? false,
    externalWhere: deal?.externalWhere ?? '',
    externalWhen: deal?.externalWhen ? formatDate(deal?.externalWhen, 'Y-m-d') : '',
    abort: deal?.abort ?? false,
    abortDate: deal?.abortDate ? formatDate(deal?.abortDate, 'Y-m-d') : '',
    abortReason: deal?.abortReason ?? null,
    abortNotice: deal?.abortNotice ?? '',
    contractLocation: deal?.contractLocation ?? '',
    degreeDesignation: deal?.degreeDesignation ?? ''
  });

  const [initialFormValues] = useState<FormValues>(formValues);

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId>('general');
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        if (deal?.id) {
          await ApiClient.put(`/deals/${deal.id}`, transformFormValues(formValues));
        } else {
          await ApiClient.post('/deals', transformFormValues(formValues));
        }
        onSubmitSuccess('Erfolgreich gespeichert', false);
      } catch (error) {
        onSubmitSuccess('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        onModalClose();
        setValidated(false);
      }
    }
  };

  const transformFormValues = (values: FormValues): FormValues => {
    const transformedValues = { ...values };
    transformedValues.priceTotalGross = transformedValues.priceTotalGross.replace(',', '.');
    transformedValues.priceTax = transformedValues.priceTax.replace(',', '.');
    transformedValues.priceTotalNet = transformedValues.priceTotalNet.replace(',', '.');
    return transformedValues;
  };

  return (
    <Modal centered size="xl" show onHide={onModalClose} backdrop="static" keyboard={false} fullscreen="xl-down">
      <Modal.Header>
        <div>
          <Modal.Title>
            {' '}
            <h4>{modalTitle}</h4>
          </Modal.Title>
          {!checkIfDataChanged() ? (
            <span>Keine Änderungen</span>
          ) : (
            <span className="text-danger">Änderungen</span>
          )}
        </div>
        <div className="d-flex">
          <Dropdown className="me-2">
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              className="btn-soft-primary dropdown-no-arrow round-button"
              style={{ padding: 0 }}
            >
              <FontAwesomeIcon icon={faEllipsis} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={() => hiddenSubmitButtonRef.current?.click()}
          >
            Fertig
            {isLoading && (
              <Spinner
                className="ms-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              ></Spinner>
            )}
          </Button>
        </div>
      </Modal.Header>

      <Modal.Body>
        <ComboButtonGroup buttons={comboButtons} selectedCombo={selectedCombo} setSelectedCombo={setSelectedCombo} borderRadius="normal" />

        <div className="horizontal-line my-3"></div>
        <Form noValidate validated={validated} onSubmit={submit}>
          {/* General Section */}
          {selectedCombo === 'general' && (
            <Row>
              <Col>
                <FormGroup id="offerNumber" label="Angebot/Rechnung, Nummer" type="text" value={formValues.offerNumber} onChange={(e) => setFormValues({ ...formValues, offerNumber: e.target.value })} />
                <FormGroup id="offerTitle" label="Angebot/Rechnung, Titel" type="text" value={formValues.offerTitle} onChange={(e) => setFormValues({ ...formValues, offerTitle: e.target.value })} />
                <FormGroup id="offerDate" label="Angebot/Rechnung, Datum" type="date" value={formValues.offerDate} onChange={(e) => setFormValues({ ...formValues, offerDate: e.target.value })} />
              </Col>
              <Col>
                {/* <FormGroup id="jamf" label="Link zu JAMF" type="text" value={formValues.jamf} onChange={(e) => setFormValues({ ...formValues, jamf: e.target.value })} /> */}
                <FormGroup id="bgsnumber" label="BGS-Nummer" type="text" value={formValues.bgsnumber} onChange={(e) => setFormValues({ ...formValues, bgsnumber: e.target.value })} />
                <SelectGroup id="macbook" label="Macbook" options={getFieldOptions(fieldConfigs, 'lastDeal.macbook')} value={formValues.macbook.toString()} onChange={(e) => setFormValues({ ...formValues, macbook: e.target.value === 'true' })} />
                <FormGroup id="actionNumber" label="Maßnahmennummer" type="text" value={formValues.actionNumber} onChange={(e) => setFormValues({ ...formValues, actionNumber: e.target.value })} />
              </Col>
            </Row>
          )}

          {/* Times Section */}
          {selectedCombo === 'times' && (
            <Row>
              <Col>
                <FormGroup id="start" label="Startdatum" type="date" value={formValues.start} onChange={(e) => setFormValues({ ...formValues, start: e.target.value })} />
                <FormGroup id="end" label="Enddatum" type="date" value={formValues.end} onChange={(e) => setFormValues({ ...formValues, end: e.target.value })} />
              </Col>
              <Col>
                {/* <SelectGroup id="timeModel" label="Unterrichtsart" options={getFieldOptions(fieldConfigs, 'lastDeal.timeModel')} value={formValues.timeModel} onChange={(e) => setFormValues({ ...formValues, timeModel: e.target.value })} /> */}
                <FormGroup min={formValues.end} id="extendedEnd" label="Deal verlängert bis (Enddatum)" type="date" value={formValues.extendedEnd} onChange={(e) => setFormValues({ ...formValues, extendedEnd: e.target.value })} />
              </Col>
            </Row>
          )}

          {/* Finances Section */}
          {selectedCombo === 'finances' && (
            <Row>
              <Col>
                <FormGroup id="priceTotalGross" label="Preis Brutto" type="text" value={formValues.priceTotalGross} onChange={(e) => setFormValues({ ...formValues, priceTotalGross: e.target.value })} />
                <SelectGroup id="psmIncludet" label="PSM wird vom Bildungsträger bezahlt" options={getFieldOptions(fieldConfigs, 'lastDeal.psmIncludet')} value={formValues.psmIncludet.toString()} onChange={(e) => setFormValues({ ...formValues, psmIncludet: e.target.value === 'true' })} />
              </Col>
              <Col>
                <SelectGroup id="selfPay" label="Selbstzahler" options={getFieldOptions(fieldConfigs, 'lastDeal.selfPay')} value={formValues.selfPay.toString()} onChange={(e) => setFormValues({ ...formValues, selfPay: e.target.value === 'true' })} />
              </Col>
            </Row>
          )}

          {/* WV Section */}
          {selectedCombo === 'wv' && (
            <Row>
              <Col>
                <FormGroup id="contractLocation" label="Standort" type="text" value={formValues.contractLocation} onChange={(e) => setFormValues({ ...formValues, contractLocation: e.target.value })} />
              </Col>
              <Col>
                <FormGroup id="degreeDesignation" label="Maßnahmenbezeichnung" type="text" value={formValues.degreeDesignation} onChange={(e) => setFormValues({ ...formValues, degreeDesignation: e.target.value })} />
              </Col>
            </Row>
          )}

          {/* Audit Section */}
          {selectedCombo === 'audit' && (
            <Row>
              <Col>
                <SelectGroup id="externalTest" label="Externe Prüfung?" options={getFieldOptions(fieldConfigs, 'lastDeal.externalTest')} value={formValues.externalTest.toString()} onChange={(e) => setFormValues({ ...formValues, externalTest: e.target.value === 'true' })} />
                <FormGroup id="externalWhere" label="Ort der Prüfung" type="text" value={formValues.externalWhere} onChange={(e) => setFormValues({ ...formValues, externalWhere: e.target.value })} />
              </Col>
              <Col>
                <FormGroup id="externalWhen" label="Datum der Prüfung" type="date" value={formValues.externalWhen} onChange={(e) => setFormValues({ ...formValues, externalWhen: e.target.value })} />
                <SelectGroup id="externalReg" label="Teilnehmer bereits registriert?" options={getFieldOptions(fieldConfigs, 'lastDeal.externalReg')} value={formValues.externalReg.toString()} onChange={(e) => setFormValues({ ...formValues, externalReg: e.target.value === 'true' })} />
              </Col>
            </Row>
          )}

          {/* Abort Section */}
          {selectedCombo === 'abort' && (
            <Row>
              <Col>
                <SelectGroup id="abort" label="Deal abgebrochen?" options={getFieldOptions(fieldConfigs, 'lastDeal.abort')} value={formValues.abort.toString()} onChange={(e) => setFormValues({ ...formValues, abort: e.target.value === 'true' })} />
                <SelectGroup disabled={deal?.abort} id="abortReason" label="Grund des Abbruchs" options={getFieldOptions(fieldConfigs, 'lastDeal.abortReason')} value={formValues.abortReason} onChange={(e) => setFormValues({ ...formValues, abortReason: e.target.value })} />
              </Col>
              <Col>
                <FormGroup disabled={deal?.abort} id="abortDate" label="Datum des Abbruchs" type="date" value={formValues.abortDate} onChange={(e) => setFormValues({ ...formValues, abortDate: e.target.value })} />
                <FormGroup disabled={deal?.abort} id="abortNotice" label="Hinweise zum Abbruch" type="textarea" value={formValues.abortNotice} onChange={(e) => setFormValues({ ...formValues, abortNotice: e.target.value })} />
              </Col>
            </Row>
          )}

          <Button type="submit" ref={hiddenSubmitButtonRef} style={{ display: 'none' }} />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditDealModal;
