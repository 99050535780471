import React from 'react';
import { Row, Col, Table, Image } from 'react-bootstrap';
import { Absence, MediaItem } from '../../../interfaces';
import SkeletonCard from '../../skeleton/SkeletonCard';
import GenericInfosCard from '../../GenericInfosCard';
import { ApiClient } from '../../../services/ApiClient';
import StatusDropdownButton from '../../StatusDropdownButton';
import { formatDateWithoutTime } from '../../../utils/utils';
import { getEnumValue, PresencesAbsenceStateEnum } from '../../../utils/enum';
import { presencesAbsencesStateColorMap, presencesAbsencesStateIconMap } from '../../filter/iconAndColorMappings';
import { getMediaUrl } from '../../../utils/media/utils';
import Card from '../../bootstrap/card';
import { useParams } from 'react-router-dom';

interface AbsenceDetailsProps {
    isLoading?: boolean;
    absence?: Absence | null;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
}

/**
 * AbsenceDetails Component
 *
 * This component displays detailed information about a specific absence record.
 * It includes:
 * - General absence information in two info cards.
 * - A dropdown to change the absence state.
 * - A table displaying absence details for the week.
 *
 * Props:
 * - `isLoading` (boolean) - Displays skeleton loaders when data is loading.
 * - `absence` (Absence | null) - The absence data object.
 * - `onSubmitSuccess` (function) - Callback function triggered after a successful state update.
 */
const AbsenceDetails: React.FC<AbsenceDetailsProps> = ({
    isLoading,
    absence,
    onSubmitSuccess
}) => {
    const { companyId = 'oc' } = useParams();
    const handleStateChange = async (state: number) => {
        if (!absence) return;

        try {
            await ApiClient.put(`/absences/${absence.id}`, { state });
            onSubmitSuccess('Erfolgreich gespeichert');
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    function getInfo1() {
        return [
            {
                label: 'Titel',
                value: absence?.title || '-',
            },
            {
                label: 'Jahr',
                value: absence?.year || '-',
            },
            {
                label: 'Kalenderwoche',
                value: absence?.calendarWeek || '-',
            },
            {
                label: 'Start der Woche',
                value: absence?.startOfWeek ? formatDateWithoutTime(absence.startOfWeek) : '-',
            },
            {
                label: 'State',
                value: getEnumValue(PresencesAbsenceStateEnum, absence?.state.toString()),
            },
            {
                label: 'Notizen',
                value: absence?.notes || '-',
            },
        ];
    }

    const weekData = [
        { label: 'Montag', key: 'monday', date: absence?.startOfWeek },
        { label: 'Dienstag', key: 'tuesday', date: absence?.startOfWeek ? new Date(new Date(absence.startOfWeek).setDate(new Date(absence.startOfWeek).getDate() + 1)).toISOString() : null },
        { label: 'Mittwoch', key: 'wednesday', date: absence?.startOfWeek ? new Date(new Date(absence.startOfWeek).setDate(new Date(absence.startOfWeek).getDate() + 2)).toISOString() : null },
        { label: 'Donnerstag', key: 'thursday', date: absence?.startOfWeek ? new Date(new Date(absence.startOfWeek).setDate(new Date(absence.startOfWeek).getDate() + 3)).toISOString() : null },
        { label: 'Freitag', key: 'friday', date: absence?.startOfWeek ? new Date(new Date(absence.startOfWeek).setDate(new Date(absence.startOfWeek).getDate() + 4)).toISOString() : null },
    ];

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{absence?.title}</h4>
                {absence && (
                    <StatusDropdownButton
                        disabled={absence.state === 1 || absence.state === 2}
                        status={absence.state}
                        onStatusChange={handleStateChange}
                        statusColorMap={presencesAbsencesStateColorMap}
                        statusIconMap={presencesAbsencesStateIconMap}
                        filterEnum={PresencesAbsenceStateEnum}
                    />
                )}
            </div>

            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <Card className="card-block card-stretch card-height">
                            <Card.Body>
                                <h5>Unterschrift</h5>
                                {absence?.signature ? (
                                    <Image
                                        src={getMediaUrl(absence.signature)}
                                        alt="Unterschrift"
                                        fluid
                                        className="border p-2"
                                    />
                                ) : (
                                    '-'
                                )}
                                <h5 className='mt-5'>Verifikationen</h5>
                                <ul>
                                    {absence?.verifications.map((verification: MediaItem) => (
                                        <li key={verification.id}>
                                            <a href={getMediaUrl(verification)} target="_blank" rel="noopener noreferrer">
                                                {verification.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            </Row>
            <h5 className="mt-4">Abwesenheitstage</h5>
            <Table responsive className="mt-3">
                <thead>
                    <tr>
                        <th>Tag</th>
                        <th>Datum</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {absence &&
                        weekData
                            .filter(({ key }) => absence[key as keyof Absence] === 1)
                            .map(({ label, key, date }) => (
                                <tr key={key}>
                                    <td>{label}</td>
                                    <td>{date ? formatDateWithoutTime(date) : '-'}</td>
                                    <td className="text-danger">Abwesend</td>
                                </tr>
                            ))}
                </tbody>
            </Table>
        </>
    );
};

export default AbsenceDetails;
