import React, { useEffect, useRef, useState } from 'react';
import { Modal, ListGroup, Button, Row, Col, Spinner } from 'react-bootstrap';
import { Deal, FieldConfig, MediaItem } from '../interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faX, faUpload, faArrowsRotate, faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import BGSImageEditor, { ImageEditorRef } from './BGSImageEditor';
import { getMediaUrl } from '../utils/media/utils';
import FileDropzone from './media/FileDropZone';
import { ApiClient } from '../services/ApiClient';
import { useToast } from '../services/context/ToastContext';
import { formatDate } from '../utils/utils';
import ConfirmationModal from './ConfirmationModal';
import SendMailModal from './participants/modal/SendMailModal';
import { EmailTemplateType } from '../utils/enum';
import BgsStatusIndicator from './BgsStatusIndicator';

interface BGSViewerModalProps {
    handleClose: () => void;
    title?: string;
    fieldConfigs?: FieldConfig[];
    dealId: number
}

/**
 * BGSViewerModal Component
 *
 * This component serves as a modal for managing "Bildungsgutschein" (BGS) documents associated with a deal.
 * It provides features for viewing, editing, replacing, uploading, and resetting documents,
 * as well as displaying the current BGS status.
 *
 * Props:
 * - handleClose (function): Callback to close the modal.
 * - title (string, optional): Title to display in the modal header.
 * - fieldConfigs (FieldConfig[], optional): Configurations for dynamic dropdowns and fields.
 * - dealId (number): ID of the deal whose BGS documents are managed.
 */
const BGSViewerModal: React.FC<BGSViewerModalProps> = ({
    handleClose,
    title,
    fieldConfigs,
    dealId,
}) => {
    const [deal, setDeal] = useState<Deal>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [blankoBgs, setBlankoBgs] = useState<MediaItem | null>();
    const [blankoBgsCopy, setBlankoBgsCopy] = useState<MediaItem | null>();
    const [filledBGS, setFilledBGS] = useState<MediaItem | null>();
    const [selectedMedia, setSelectedMedia] = useState<MediaItem | null>();
    const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [showConfirmResetModal, setShowConfirmResetModal] = useState<boolean>(false);
    const [showReplaceDocumentModal, setShowReplaceDocumentModal] = useState<boolean>(false);
    const [sendMailConfig, setSendMailConfig] = useState<{
        title: string;
        email: string;
        dealId: number;
        type: number;
    } | null>(null);

    const { showToast } = useToast();
    const imageEditorRef = useRef<ImageEditorRef>(null);

    const fetchDeal = async () => {
        try {
            const res = await ApiClient.get(`/deals/${dealId}`);
            setDealData(res.data as Deal)
        } catch (error) {
            console.error('Error fetching deal:', error);
        } finally {
            setIsLoading(false);
        }
    };

    function setDealData(deal: Deal) {
        setDeal(deal);
        setBlankoBgs(deal.bgs)
        setBlankoBgsCopy(deal.bgs ? { ...deal.bgs, id: 0 } : null)
        setFilledBGS(deal.bgsFilled)
        setSelectedMedia(deal.bgs ?? null)
    }

    useEffect(() => {
        fetchDeal()
    }, []);

    useEffect(() => {
        setSelectedMedia(blankoBgsCopy ?? null);
    }, [blankoBgs]);

    const handleSaveData = async () => {
        setIsLoading(true)
        if (imageEditorRef.current) {
            await imageEditorRef.current.saveData();
            fetchDeal()
        }
        setIsLoading(false)
        setIsInEditMode(false)
    };

    const resetFormData = async () => {
        const res = await ApiClient.put(`/deals/${deal?.id}`, { measureData: null });
        setDealData(res.data as Deal)
    }

    const loadInitialData = () => {
        if (imageEditorRef.current) {
            imageEditorRef.current.loadInitialData();
        }
    }

    const handleMediaSelect = (mediaItem: MediaItem) => {
        setSelectedMedia(mediaItem);
        setIsInEditMode(false)
    };

    const handleFormChange = (hasChanged: boolean) => {
        setFormChanged(hasChanged)
    }

    const handleBgsUpload = async (newFiles: File[]) => {
        if (!newFiles.length) {
            return
        }
        setIsLoading(true)
        try {
            for (let index = 0; index < newFiles.length; index++) {
                const file = newFiles[index];
                const formData = new FormData();
                formData.append('file', file);
                formData.append('dealId', deal?.id.toString() ?? '');
                await ApiClient.post('/deals/uploadBgs', formData);
            }
            setSelectedMedia(blankoBgsCopy ?? null)
            showToast(`Erfolgreich gespeichert`, false);
            fetchDeal()
        } catch (error) {
            showToast('Fehler beim Speichern', true);
            console.error('Error during file upload:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const replaceDocument = async () => {
        try {
            setIsLoading(true);
            await ApiClient.delete(`/media/${blankoBgs?.id}`);
            filledBGS && await ApiClient.delete(`/media/${filledBGS?.id}`);
            const res = await ApiClient.put(`/deals/${deal?.id}`, { measureData: null, bgs: null, bgsFilled: null });
            setDealData(res.data as Deal)
            setSelectedMedia(null)
            setBlankoBgs(null)
            setBlankoBgsCopy(null)
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const getSendMailModalConfig = (receiverType: 'advisor') => {
        if (receiverType === 'advisor') {
            setSendMailConfig({
                title: `BGS an AfA Berater senden`,
                email: '',
                dealId: dealId,
                type: EmailTemplateType.BGS_TO_AFA
            });
        }
    };

    const handleSuccess = (message?: string, isError?: boolean) => {
        if (message) {
            showToast(message, isError);
        }
        fetchDeal()
        setSendMailConfig(null);
    };

    return (
        <Modal show onHide={handleClose} keyboard={false} fullscreen>
            {(showConfirmResetModal || showReplaceDocumentModal || sendMailConfig) && <div className="custom-backdrop"></div>}
            {isLoading || !deal ?
                <Modal.Body className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </Modal.Body> :
                <>
                    <Modal.Header className="d-flex flex-column flex-md-row align-items-start justify-content-lg-between align-items-lg-center">
                        <Row className="w-100">
                            <Col lg={2}>
                                <div className="d-flex align-items-center mb-3 mb-lg-0">
                                    <Button
                                        variant="secondary"
                                        className="btn-soft-primary round-modal-close-button me-2"
                                        onClick={handleClose}
                                    >
                                        <FontAwesomeIcon icon={faX} size="sm" />
                                    </Button>

                                    <div>
                                        <h5 className="mb-0 text-break">{title}</h5>
                                        <p className="p-0 m-0 text-gray text-break">{deal.bgsnumber}</p>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={10}>
                                <div className="d-flex flex-column flex-md-row justify-content-between">
                                    <div className="mb-3 mb-lg-0">
                                        {!blankoBgs ? (
                                            <>
                                                <h5 className="mb-0">Original</h5>
                                                <p className="p-0 m-0 text-gray">Noch nicht vorhanden</p>
                                            </>
                                        ) : selectedMedia?.id === blankoBgsCopy?.id ? (
                                            <>
                                                <h5 className="mb-0">Kopie zum Bearbeiten</h5>
                                                <p className="p-0 m-0 text-gray">
                                                    {filledBGS
                                                        ? formatDate(filledBGS.created, "d.m.Y [um] H:i:s [Uhr]")
                                                        : "Noch nicht bearbeitet"}
                                                </p>
                                            </>
                                        ) : selectedMedia?.id === blankoBgs?.id && (
                                            <>
                                                <h5 className="mb-0">Original</h5>
                                                <p className="p-0 m-0 text-gray">
                                                    {formatDate(blankoBgs.created, "d.m.Y [um] H:i:s [Uhr]")}
                                                </p>
                                            </>
                                        )}
                                    </div>

                                    <div>
                                        {blankoBgs && selectedMedia?.id === blankoBgs?.id && (
                                            <>
                                                <Button
                                                    variant="secondary"
                                                    className="btn-soft-primary me-2"
                                                    onClick={() => setShowReplaceDocumentModal(true)}
                                                >
                                                    Dokument ersetzen <FontAwesomeIcon icon={faUpload} size="sm" />
                                                </Button>

                                                <a
                                                    href={blankoBgs ? getMediaUrl(blankoBgs) : ""}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <Button variant="secondary" className="btn-soft-primary me-2">
                                                        Herunterladen <FontAwesomeIcon icon={faDownload} size="sm" />
                                                    </Button>
                                                </a>
                                            </>
                                        )}

                                        {selectedMedia?.id === blankoBgsCopy?.id &&
                                            (!isInEditMode ? (
                                                <>
                                                    {filledBGS && (
                                                        <Button
                                                            variant="secondary"
                                                            className="btn-soft-primary me-2"
                                                            onClick={() => setShowConfirmResetModal(true)}
                                                        >
                                                            Original wiederherstellen{" "}
                                                            <FontAwesomeIcon icon={faArrowsRotate} size="sm" />
                                                        </Button>
                                                    )}

                                                    {filledBGS && (
                                                        <a
                                                            href={filledBGS ? getMediaUrl(filledBGS) : ""}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <Button
                                                                variant="secondary"
                                                                className="btn-soft-primary me-2"
                                                                disabled={!filledBGS}
                                                            >
                                                                Herunterladen <FontAwesomeIcon icon={faDownload} size="sm" />
                                                            </Button>
                                                        </a>
                                                    )}
                                                    {deal.bgs && <Button
                                                        variant="primary"
                                                        className="me-2"
                                                        onClick={() => setIsInEditMode(true)}
                                                    >
                                                        Bearbeiten
                                                    </Button>}

                                                    {/* Show "An AfA Berater senden" button only if BGS status is "BGS ausgefüllt" (bgsStatus === 3)*/}
                                                    {deal.bgsStatus === 3 && <Button
                                                        variant="primary"
                                                        onClick={() => getSendMailModalConfig('advisor')}
                                                    >
                                                        An AfA Berater senden <FontAwesomeIcon icon={faPaperPlaneTop} />
                                                    </Button>}
                                                </>
                                            ) : (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div className="d-flex flex-column me-5">
                                                        <h5 className="m-0">Bearbeiten</h5>
                                                        <span className="w-150-px">
                                                            {formChanged ? (
                                                                <span className="text-danger">Änderungen</span>
                                                            ) : (
                                                                <span>Keine Änderungen</span>
                                                            )}
                                                        </span>
                                                    </div>

                                                    <Button
                                                        variant="secondary"
                                                        className="btn-soft-primary me-2"
                                                        onClick={() => {
                                                            loadInitialData();
                                                            setIsInEditMode(false);
                                                        }}
                                                    >
                                                        Abbrechen
                                                    </Button>

                                                    <Button
                                                        variant="primary"
                                                        className="me-2"
                                                        onClick={handleSaveData}
                                                        disabled={isLoading}
                                                    >
                                                        Speichern
                                                        {isLoading && (
                                                            <Spinner
                                                                className="ms-2"
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                    </Button>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Header>

                    <Modal.Body className="py-0">
                        <Row className='h-100'>
                            <Col lg={2}>
                                <div className='my-4'>
                                    <div className='bg-light-blue p-2 ps-4 rounded'> <BgsStatusIndicator fieldConfigs={fieldConfigs} bgsStatus={deal?.bgsStatus ?? 0}></BgsStatusIndicator> </div>
                                </div>

                                <div className='horizontal-line'></div>

                                <div className="media-list mt-5" style={{ overflowY: 'auto' }}>
                                    <ListGroup>
                                        {!blankoBgs ? (
                                            <ListGroup.Item
                                                className='py-3 no-border rounded'
                                                key={0}
                                                active={true}
                                                action
                                            >
                                                Original
                                                <p className={`p-0 m-0 text-gray`}>Noch nicht vorhanden</p>
                                            </ListGroup.Item>
                                        ) : (
                                            <>
                                                {blankoBgsCopy && (
                                                    <ListGroup.Item
                                                        className={`py-3 no-border rounded ${selectedMedia?.id === blankoBgsCopy?.id ? '' : 'text-gray'}`}
                                                        key={blankoBgsCopy.id}
                                                        active={selectedMedia?.id === blankoBgsCopy.id}
                                                        action
                                                        onClick={() => handleMediaSelect(blankoBgsCopy)}
                                                    >
                                                        Kopie zum Bearbeiten
                                                        <p className={`p-0 m-0 text-gray`}>{filledBGS ? formatDate(filledBGS.created, `d.m.Y [um] H:i:s [Uhr]`) : 'Noch nicht bearbeitet'} </p>
                                                    </ListGroup.Item>
                                                )}

                                                {blankoBgs && (
                                                    <ListGroup.Item
                                                        className={`py-3 no-border rounded ${selectedMedia?.id === blankoBgs?.id ? '' : 'text-gray'}`}
                                                        key={blankoBgs?.id}
                                                        active={selectedMedia?.id === blankoBgs?.id}
                                                        action
                                                        onClick={() => handleMediaSelect(blankoBgs!)}
                                                    >
                                                        Original
                                                        <p className={`p-0 m-0 text-gray`}>{formatDate(blankoBgs.created, `d.m.Y [um] H:i:s [Uhr]`)}</p>
                                                    </ListGroup.Item>
                                                )}
                                            </>
                                        )}
                                    </ListGroup>
                                </div></Col>
                            <Col lg={10} className='h-100 bg-grey'>
                                {selectedMedia && (
                                    selectedMedia.id === blankoBgs?.id ? (
                                        <div className="bgs-viewer-container p-5">
                                            {selectedMedia.mediaType === 'image' && (
                                                <img
                                                    src={`${getMediaUrl(selectedMedia)}?nocache=${selectedMedia.modified}`}
                                                    alt={selectedMedia.title}
                                                    className="bgs-viewer-image"
                                                />
                                            )}
                                        </div>
                                    ) :
                                        blankoBgs && <BGSImageEditor deal={deal} initialFormValues={deal.measureData && !(deal.measureData.length === 0) ? deal.measureData : null} ref={imageEditorRef} imageUrl={`${getMediaUrl(blankoBgs)}?nocache=${blankoBgs.modified}`} inEditMode={isInEditMode} onFormChange={handleFormChange} />
                                )}

                                {!selectedMedia &&
                                    <div className='d-flex justify-content-center align-items-center h-100 w-100'>
                                        <FileDropzone
                                            accept={{
                                                'image/*': ['.jpg', '.jpeg', '.png'],
                                                'application/pdf': ['.pdf'],
                                            }}
                                            multiple={false}
                                            onFilesChange={(files) => handleBgsUpload(files)}>
                                        </FileDropzone>
                                    </div>}
                            </Col>
                        </Row>
                    </Modal.Body>
                </>
            }

            <ConfirmationModal
                show={showConfirmResetModal}
                handleClose={() => setShowConfirmResetModal(false)}
                handleConfirm={async () => {
                    await resetFormData();
                    setShowConfirmResetModal(false);
                }}
                title="Original wiederherstellen?"
                message="Möchten Sie die Änderungen wirklich zurücksetzen und die Originaldaten wiederherstellen?"
            />

            <ConfirmationModal
                show={showReplaceDocumentModal}
                handleClose={() => setShowReplaceDocumentModal(false)}
                handleConfirm={async () => {
                    await replaceDocument();
                    setShowReplaceDocumentModal(false);
                }}
                title="Dokument ersetzen?"
                message="Möchten Sie das Dokument wirklich ersetzen, alle Daten gehen dabei verloren?"
            />

            {sendMailConfig && (
                <SendMailModal
                    dealId={sendMailConfig.dealId}
                    email={sendMailConfig.email}
                    title={sendMailConfig.title}
                    type={sendMailConfig.type}
                    onModalClose={() => setSendMailConfig(null)}
                    onSubmitSuccess={handleSuccess}
                />
            )}
        </Modal>
    );
};

export default BGSViewerModal;
