import React, { useState } from 'react';
import { FieldConfig, Participant, } from '../../../interfaces';
import Card from '../../bootstrap/card';
import { Button, Col, Row } from 'react-bootstrap';
import ParticipantInitialsComponent from '../../ParticipantInitialsComponent';
import StatusDisplay from '../StatusDisplay';
import { getInitials } from '../../../utils/utils';
import Skeleton from 'react-loading-skeleton';
import ParticipantHeaderDropdown from '../../ParticipantHeaderDropdown';
import ArchiveModal from '../../ArchiveModal';
import { usePermissions } from '../../../hooks/usePermissions';
import { PermissionsEnum } from '../../../utils/enum';
import AddEditParticipantModal from '../modal/AddEditParticipantModal';
import { ApiClient } from '../../../services/ApiClient';
import InfoDialog from '../../InfoDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';

interface ParticipantDetailsHeaderProps {
    isLoading?: boolean;
    participant?: Participant
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
    fieldConfigs: FieldConfig[]
}

/**
 * ParticipantDetailsHeader Component
 * 
 * This component displays the header section for a participant's details. It includes the participant's
 * initials, title, status, and provides actions like editing or archiving the participant.
 * 
 * Props:
 * - `isLoading` (boolean): Indicates whether the component is in a loading state, displaying skeleton placeholders.
 * - `participant` (Participant): The participant data object containing details like title, ID, and status.
 * - `onSubmitSuccess` (function): Callback function triggered after a successful archive or edit action.
 * - `fieldConfigs` (FieldConfig[]): Array of field configurations for rendering dynamic options.
 * 
 * Example Usage:
 * 
 * <ParticipantDetailsHeader
 *   isLoading={false}
 *   participant={participantData}
 *   onSubmitSuccess={handleSuccess}
 *   fieldConfigs={fieldConfigs}
 * />
 */
const ParticipantDetailsHeader: React.FC<ParticipantDetailsHeaderProps> = ({
    isLoading,
    participant,
    onSubmitSuccess,
    fieldConfigs
}) => {
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [dialogContent, setDialogContent] = useState<{ headline: string; message: string } | null>(null);

    const { userHasPermissionByRight } = usePermissions();

    const migrateParticipantIntoStage = async () => {
        try {
            const response = await ApiClient.post(`/participants/${participant?.id}/migrateIntoStage`, {});
            if (response.data.type === 'toast') {
                setDialogContent({
                    headline: response.data.headline,
                    message: response.data.message,
                });

                setTimeout(() => {
                    setDialogContent(null);
                }, 7000);

            } else {
                onSubmitSuccess(`${response.data.headline}: ${response.data.message}`);
            }
        } catch (error: any) {
            onSubmitSuccess(error.response.data[1] ?? 'Fehler beim Migrieren in die Stage', true);
            console.error('Error during migration into stage:', error);
        }
    };

    const handleDropdownChange = (action: string) => {
        if (action === 'archive') {
            setShowArchiveModal(true)
        }

        if (action === 'viewAsParticipant') {
            migrateParticipantIntoStage()
        }

        if (action === 'openHubSpotTN') {
            const url = `https://app-eu1.hubspot.com/contacts/26690801/record/0-1/${participant?.hubspotId}`;
            window.open(url, '_blank')
        }
        if (action === 'openHubSpotLastDeal') {
            const url = `https://app-eu1.hubspot.com/contacts/26690801/record/0-3/${participant?.lastDeal.hubspotDealId}`;
            window.open(url, '_blank')
        }
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Row>
                        <Col >
                            <div className="d-flex justify-content-between align-items-start align-items-lg-center flex-wrap mb-3">
                                <div className='d-flex justify-content-center align-items-center'>
                                    <ParticipantInitialsComponent size={40} initials={getInitials(participant?.title ?? '')} ></ParticipantInitialsComponent>
                                    <h4 className='mb-0 ms-3'>{isLoading ? <Skeleton width={150} /> : (participant?.title || '-')}</h4>
                                </div>

                                <div className='d-flex mt-2 mt-lg-0'>
                                    <ParticipantHeaderDropdown disabled={!!dialogContent} participant={participant} onActionClick={handleDropdownChange}></ParticipantHeaderDropdown>
                                    <Button
                                        disabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write') || !participant || !fieldConfigs}
                                        variant="primary"
                                        onClick={() => setShowAddEditModal(true)}
                                    >
                                        Bearbeiten
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {participant && <StatusDisplay participant={participant} fieldConfigs={fieldConfigs}
                            />}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {showArchiveModal && <ArchiveModal
                modalTitle="Auswahl archivieren?"
                entityIds={[participant?.id!]}
                entityType='participants'
                onSubmit={onSubmitSuccess}
                onModalClose={() => setShowArchiveModal(false)}>
            </ArchiveModal>}

            {showAddEditModal && <AddEditParticipantModal
                participant={participant}
                modalTitle="Teilnehmer bearbeiten"
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
                fieldConfigs={fieldConfigs}
            >
            </AddEditParticipantModal>
            }

            {dialogContent && (
                <InfoDialog onClose={() => setDialogContent(null)} title={dialogContent.headline}>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <span className="text-black text-break">{dialogContent.message}</span>
                        <FontAwesomeIcon icon={faCircleCheck} className="text-success ms-2" size="lg" />
                    </div>
                </InfoDialog>
            )}
        </>
    );
};

export default ParticipantDetailsHeader;
