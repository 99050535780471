import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import { Education, FieldConfig } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import EducationDetails from '../../components/educations/detail/EducationDetails';
import { fetchAndCombineFieldConfigs } from '../../utils/utils';

function EducationDetail() {
  const { educationId } = useParams();
  const [education, setEducation] = useState<Education>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();
  const { show, message, error, showToast, hideToast } = useToast();
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewEducations, 'read');

      if (hasPermission) {
        educationId && fetchEducation(educationId);
        if (!fieldConfigs || fieldConfigs.length === 0) {
          fetchAndCombineFieldConfigs(['educations'], setFieldConfigs);
        }
      } else {
        navigate('/errors/error404');
      }
    }
  }, [educationId, permissionsLoaded]);

  const fetchEducation = async (educationId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/educations/${educationId}`);
      const educationResponse = response.data;
      setEducation(educationResponse);
      setIsLoading(false);
    } catch (error) { 
      showToast('Fehler beim laden der Daten', true)
    }
  };

  const handleModalSubmit = () => {
    setEducation(undefined);
    educationId && fetchEducation(educationId);
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <EducationDetails education={education} isLoading={isLoading} onSubmitSuccess={handleModalSubmit} fieldConfigs={fieldConfigs}></EducationDetails>
            </Card.Body>
          </Card></Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default EducationDetail;
