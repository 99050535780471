import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { ApiClient } from '../../services/ApiClient';
import { Absence } from '../../interfaces';
import { usePermissions } from '../../hooks/usePermissions';
import { useToast } from '../../services/context/ToastContext';
import NotificationToast from '../../components/NotificationToast';
import AbsenceDetails from '../../components/absences/detail/AbsencesDetails';

function AbsenceDetail() {
  const { absenceId } = useParams();
  const { showToast, show, hideToast, message, error } = useToast();
  const [absence, setAbsence] = useState<Absence | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewAbsences, 'read');

      if (hasPermission) {
        absenceId && fetchAbsence(absenceId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [absenceId, permissionsLoaded]);

  const fetchAbsence = async (absenceId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/absences/${absenceId}`);
      const settingResponse = response.data;
      setAbsence(settingResponse);
      setIsLoading(false);
    } catch (error) { }
  };

  const handleModalSubmit = (message?: string, isError?: boolean) => {
    absenceId && fetchAbsence(absenceId);
    if (message) {
      showToast(message, isError);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <AbsenceDetails absence={absence} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></AbsenceDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default AbsenceDetail;
