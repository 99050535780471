import React, { useEffect, useState } from 'react';
import { Dropdown, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Nestable, { RenderItem } from 'react-nestable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faChevronDown,
    faChevronUp,
    faEllipsisH,
    faInfoCircle,
    faLock,
    faLockOpen,
    faCircle,
    faSpinner,
    faHourglass,
    faUserUnlock
} from '@fortawesome/pro-regular-svg-icons';
import { formatDate } from '../../../utils/utils';
import { ApiClient } from '../../../services/ApiClient';
import PortalWrapper from '../../PortalWrapper';
import { useToast } from '../../../services/context/ToastContext';
import { DealModuleData, } from '../../../interfaces';

interface DealModulesProps {
    dealId: number;
}

/**
 * DealModules Component
 * 
 * This component fetches and displays the list of modules for a specific deal.
 * It uses a nested list structure (via `react-nestable`) to render modules and their associated units.
 * Each module displays its title, status, date range, progress, and an action dropdown.
 * The user can open a modal to unlock a module manually.
 * 
 * Props:
 * - `dealId` (number): The ID of the deal for which modules should be fetched.
 */

const DealModules: React.FC<DealModulesProps> = ({ dealId }) => {
    const [modules, setModules] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { showToast } = useToast();

    const fetchModules = async () => {
        try {
            setIsLoading(true);
            const response = await ApiClient.get(`/deals/${dealId}/modules`);
            const transformedModules = response.data.map((item: DealModuleData) => ({
                id: item.module.id,
                title: item.module.title,
                start: item.start,
                end: item.end,
                durationUes: item.module.durationUes,
                state: item.state,
                lastManualDate: item.lastManualDate,
                lwId: item.lwId,
                progress: item.progress,
                children: item.units?.map((unit) => ({
                    id: unit.id,
                    title: unit.title,
                    durationUes: unit.durationUes,
                    lwId: unit.lwId
                })),
            }));

            setModules(transformedModules);
        } catch (error) {
            console.error('Error fetching modules:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const setLearningProgress = async (dealId: number, lwId: string, complete: boolean) => {
        try {
            const payload = {
                lwId,
                complete
            }
            await ApiClient.post(`/deals/${dealId}/learningProgress`, payload);
            fetchModules()
            showToast(`Lerneinheit - ${lwId} - wurde auf ${complete ? '100%' : '0%'} gesetzt`);
        } catch (error: any) {
            showToast(error.response.data[1] ?? 'Etwas ist schief gelaufen', true);
            console.error('Error setting learning progress:', error);
        }
    };

    const setModuleAccess = async (dealId: number, lwId: string, locked: boolean) => {
        try {
            const payload = {
                lwId,
                locked
            }
            await ApiClient.post(`/deals/${dealId}/moduleAccess`, payload);
            fetchModules()
            showToast(`Modul - ${lwId} - wurde ${locked ? 'gesperrt' : 'entsperrt'}`);
        } catch (error: any) {
            showToast(error.response.data[1] ?? 'Etwas ist schief gelaufen', true);
            console.error('Error setting module access:', error);
        }
    };

    useEffect(() => {
        fetchModules();
    }, [dealId]);

    const getProgressIcon = (progress: number) => {
        if (progress === 100) {
            return <FontAwesomeIcon className="text-success" icon={faCheckCircle} />;
        }
        if (progress === 0) {
            return <FontAwesomeIcon className="text-danger" icon={faCircle} />;
        }
        return <FontAwesomeIcon className="text-warning" icon={faSpinner} />;
    };

    const getTooltip = (part1?: string, part2?: string) => {
        const tooltipContent = (
            <>
                {part1 && <div>{part1}</div>}
                {part2 && <div>{part2}</div>}
            </>
        );

        return <Tooltip id="tooltip">{tooltipContent}</Tooltip>;
    };

    const getTooltipContent = (state: string) => {
        switch (state) {
            case 'lock':
                return `Manuell gesperrt`;
            case 'sended':
                return 'Automatisch freigeschaltet';
            case 'open':
                return 'Warten auf automatische Freischaltung';
            case 'unlock':
                return `Manuell freigeschaltet`;
            default:
                return '';
        }
    };

    const renderItem = ({
        item,
        collapseIcon,
        depth,
    }: {
        item: any;
        index: number;
        depth: number;
        isDraggable: boolean;
        collapseIcon: React.ReactNode;
        handler: React.ReactNode;
    }) => {
        const isModule = depth === 0;
        let stateIcon;
        let stateIconClass;

        switch (item.state) {
            case 'lock':
                stateIcon = faLock;
                stateIconClass = 'text-danger';
                break;
            case 'sended':
                stateIcon = faLockOpen;
                stateIconClass = 'text-success';
                break;
            case 'open':
                stateIcon = faHourglass;
                stateIconClass = 'text-warning';
                break;
            case 'unlock':
                stateIcon = faUserUnlock;
                stateIconClass = 'text-success';
                break;
            default:
                stateIcon = faCircle;
                stateIconClass = 'text-muted';
        }

        return (
            <div className="d-flex align-items-center gap-3 p-2">
                <div
                    className="d-flex align-items-center word-break"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (
                            React.isValidElement(collapseIcon)
                        ) {
                            collapseIcon.props.onClick(e);
                        }
                    }}
                >
                    {collapseIcon}
                    <span className="ms-2 text-light-black cursor-pointer">{item.title}</span>
                </div>

                <div className="horizontal-line flex-grow-1"></div>

                <div className="d-flex align-items-center justify-content-end gap-2">
                    {isModule && (
                        <>
                            {/* Status Icon */}
                            <div className="d-flex justify-content-center" style={{ width: '20px' }}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={getTooltip(
                                        getTooltipContent(item.state),
                                        item.lastManualDate ? `am ${formatDate(item.lastManualDate, 'd.m.yy')}` : ''
                                    )}
                                >
                                    <FontAwesomeIcon
                                        className={`${stateIconClass} cursor-pointer`}
                                        icon={stateIcon}
                                    />
                                </OverlayTrigger>
                            </div>

                            {/* Date Range */}
                            <div className="text-start" style={{ width: '130px' }}>
                                <span className="text-nowrap text-light-black">
                                    {formatDate(item.start, 'd.m.yy')} - {formatDate(item.end, 'd.m.yy')}
                                </span>
                            </div>

                            {item.progress && item.progress >= 0 && <>
                                <div className="text-start" >
                                    <span>·</span>
                                </div>

                                <div className="text-start" style={{ width: '50px' }}>
                                    <span className="text-nowrap text-light-black">
                                        {getProgressIcon(item.progress)} {item.progress}%
                                    </span>
                                </div>

                                <div className="text-start">
                                    <span>·</span>
                                </div>
                            </>}
                        </>
                    )}

                    {/* Info Icon */}
                    <div className="text-start" style={{ width: '20px' }}>
                        <OverlayTrigger
                            placement="top"
                            overlay={getTooltip(item?.lwId, `${item?.durationUes ?? ''} UE`)}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} className='cursor-pointer' />
                        </OverlayTrigger>
                    </div>

                    {/* Actions Dropdown */}
                    <div className="text-start">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary no-caret cursor-pointer py-0 px-1"
                            >
                                <FontAwesomeIcon icon={faEllipsisH} />
                            </Dropdown.Toggle>
                            <PortalWrapper>
                                <Dropdown.Menu>
                                    {isModule && <>
                                        {(item.state !== 'sended' && item.state !== 'unlock') && <Dropdown.Item as="div" onClick={() => setModuleAccess(dealId, item.lwId, false)}>
                                            <FontAwesomeIcon className="text-primary" width={30} icon={faUserUnlock} />
                                            Sofort freischalten
                                        </Dropdown.Item>}

                                        {item.state !== 'lock' && <Dropdown.Item as="div" onClick={() => setModuleAccess(dealId, item.lwId, true)}>
                                            <FontAwesomeIcon className="text-primary" width={30} icon={faLock} />
                                            Sperren
                                        </Dropdown.Item>}
                                    </>}

                                    {!isModule && <>
                                        <Dropdown.Item as="div" onClick={() => setLearningProgress(dealId, item.lwId, false)}>
                                            <FontAwesomeIcon className="text-primary" width={30} icon={faCircle} />
                                            Fortschritt 0%
                                        </Dropdown.Item>

                                        <Dropdown.Item as="div" onClick={() => setLearningProgress(dealId, item.lwId, true)}>
                                            <FontAwesomeIcon className="text-primary" width={30} icon={faCheckCircle} />
                                            Fortschritt 100%
                                        </Dropdown.Item>
                                    </>
                                    }
                                </Dropdown.Menu>
                            </PortalWrapper>
                        </Dropdown>
                    </div>

                </div>
            </div>
        );
    };

    return (
        <div>
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center 50-vw" style={{ height: '500px' }}>
                    <Spinner animation="border" role="status"></Spinner>
                </div>
            ) : (
                <Nestable
                    disableDrag
                    items={modules}
                    renderItem={renderItem as RenderItem}
                    collapsed
                    renderCollapseIcon={({ isCollapsed }) =>
                        isCollapsed ? (
                            <FontAwesomeIcon icon={faChevronDown} />
                        ) : (
                            <FontAwesomeIcon icon={faChevronUp} />
                        )
                    }
                />
            )}
        </div>
    );
};

export default DealModules;
