import { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";

interface HoldButtonProps {
    label: string;
    icon: any;
    onConfirm: () => Promise<void>;
    disabled?: boolean;
}

/**
 * HoldButton Component
 * 
 * This component renders a button that requires the user to hold it down
 * for a short duration before triggering an API call. It provides visual
 * feedback through different background colors and icons based on the 
 * status of the action:
 * 
 * - `idle` (default): Light grey background
 * - `holding`: Progress bar fills up
 * - `loading`: Yellow background while processing
 * - `success`: Green background on successful completion
 * - `error`: Red background if the operation fails
 * 
 * Props:
 * - `label` (string): The text displayed on the button.
 * - `icon` (FontAwesome icon): The icon displayed next to the text.
 * - `onConfirm` (function): A promise-based function executed when the hold completes.
 * 
 * Usage:
 * ```tsx
 * <HoldButton
 *   label="Delete Account"
 *   icon={faTrash}
 *   onConfirm={() => apiCallFunction()}
 * />
 * ```
 */
const HoldButton: React.FC<HoldButtonProps> = ({ label, icon, onConfirm, disabled }) => {
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState<"idle" | "holding" | "loading" | "success" | "error">("idle");
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const handleMouseDown = () => {
        if (disabled) return;
        setStatus("holding");
        setProgress(0);

        intervalRef.current = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(intervalRef.current as NodeJS.Timeout);
                    setStatus("loading");
                    setProgress(0);

                    onConfirm()
                        .then(() => {
                            setStatus("success");
                            setProgress(0);
                        })
                        .catch(() => {
                            setStatus("error");
                            setProgress(0);

                            setTimeout(() => {
                                setStatus("idle");
                            }, 3000);
                        });
                    return 100;
                }
                return prev + 2;
            });
        }, 60);
    };

    const handleMouseUp = () => {
        if (status === "holding") {
            clearInterval(intervalRef.current as NodeJS.Timeout);
            setStatus("idle");
            setProgress(0);
        }
    };

    return (
        <div
        className={`hold-button ${status} ${disabled ? "opacity-50" : ""}`}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        >
            {/* Loading Bar */}
            <div className="hold-button-progress" style={{ width: `${progress}%` }}></div>

            {/* Content */}
            <div className={`hold-button-content ${status}`}>
                <FontAwesomeIcon
                    icon={
                        status === "success"
                            ? faCircleCheck
                            : status === "loading"
                                ? faSpinner
                                : status === "error"
                                    ? faExclamationTriangle
                                    : icon
                    }
                    spin={status === "loading"}
                />
                <span className="font-size-12">
                    {status === "success"
                        ? "Erfolgreich!"
                        : status === "error"
                            ? "Fehlgeschlagen!"
                            : label}
                </span>
            </div>
        </div>
    );
};

export default HoldButton;
