import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { ApiClient } from '../../services/ApiClient';
import { Presence } from '../../interfaces';
import { usePermissions } from '../../hooks/usePermissions';
import PresenceDetails from '../../components/presences/detail/PresenceDetails';
import { useToast } from '../../services/context/ToastContext';
import NotificationToast from '../../components/NotificationToast';

function PresenceDetail() {
  const { presenceId } = useParams();
  const { showToast, show, hideToast, message, error } = useToast();
  const [presence, setPresence] = useState<Presence | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewPresences, 'read');

      if (hasPermission) {
        presenceId && fetchPresence(presenceId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [presenceId, permissionsLoaded]);

  const fetchPresence = async (presenceId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/presences/${presenceId}`);
      const settingResponse = response.data;
      setPresence(settingResponse);
      setIsLoading(false);
    } catch (error) { }
  };

  const handleModalSubmit = (message?: string, isError?: boolean) => {
    presenceId && fetchPresence(presenceId);
    if (message) {
      showToast(message, isError);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <PresenceDetails presence={presence} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></PresenceDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default PresenceDetail;
